import { call, put, takeEvery } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_ACCESS_RULES_START,
    GET_ACCESS_RULES_SUCCESS,
    GET_ACCESS_RULES_FAILED,
    SET_LOGIN_START,
    SET_LOGIN_SUCCESS,
    SET_LOGIN_FAILED,
    LOCAL_UPDATE_PROFILE_INFO,
} from '../actions/actionTypes';

export function* handleGetAccessRules() {
    try {
        const url = `${API_DOMAIN}/users/profile/`;
        const data = yield call(request.get, url);
        yield put({
            type: LOCAL_UPDATE_PROFILE_INFO,
            payload: data,
        });
        return yield put({
            type: GET_ACCESS_RULES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        return yield put({ type: GET_ACCESS_RULES_FAILED, error });
    }
}

export function* handleSetLogin() {
    try {
        const url = `${API_DOMAIN}/users/set_login/`;
        const data = yield call(request.post, url);
        if (data && 'is_first_login' in data) {
            return yield put({
                type: SET_LOGIN_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: SET_LOGIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        return yield put({ type: SET_LOGIN_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_ACCESS_RULES_START, handleGetAccessRules);
    yield takeEvery(SET_LOGIN_START, handleSetLogin);
}
