import { call, put, takeLatest } from 'redux-saga/effects';
import { API_DOMAIN } from '../helpers/constants';
import { request } from '../helpers/api';
import {
    GET_REQUEST_START,
    GET_REQUEST_FAILED,
    GET_REQUEST_SUCCESS,
    CREATE_REQUEST_START,
    CREATE_REQUEST_FAILED,
    CREATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_START,
    UPDATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_FAILED,
} from '../actions/actionTypes';
import notify from '../helpers/notify';

function* handleGetRequest(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/request_infomessages/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_REQUEST_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_REQUEST_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_REQUEST_FAILED, error });
    }
}

function* handleCreateRequest(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/request_infomessages/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            notify.success(
                'Ваш запрос на отправку Информационного письма принят в работу',
            );
            return yield put({ type: CREATE_REQUEST_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_REQUEST_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_REQUEST_FAILED, error });
    }
}

function* handleUpdateRequest(action) {
    try {
        const obj = action.payload;
        const url = `${API_DOMAIN}/info_messages/request_infomessages/${obj.id}/`;
        delete obj.id;
        const data = yield call(request.put, url, obj);
        if (data) {
            return yield put({ type: UPDATE_REQUEST_SUCCESS, payload: data });
        }
        return yield put({ type: UPDATE_REQUEST_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_REQUEST_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_REQUEST_START, handleGetRequest);
    yield takeLatest(CREATE_REQUEST_START, handleCreateRequest);
    yield takeLatest(UPDATE_REQUEST_START, handleUpdateRequest);
}
