import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const GreenTextField = withStyles({
    root: {
        borderWidth: '1px !important',
        width: '516px',
        marginBottom: '0',

        '& label.Mui-focused': {
            color: '#00923A',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#00923A',
            borderWidth: 1,
        },
        '& .MuiOutlinedInput-input': {
            fontFamily: `'Roboto', Apple-System, Arial, Helvetica, sans-serif`,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#44444F',
            letterSpacing: 0,
            padding: '16px 20px',
            height: '48px',
            boxSizing: 'border-box',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E2E2EA',
                borderWidth: 1,
            },
            '&:hover fieldset': {
                borderColor: '#00923A',
                borderWidth: 1,
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00923A',
                borderWidth: 1,
            },
        },
        '& .MuiInputLabel-outlined': {
            fontFamily: `'Roboto', Apple-System, Arial, Helvetica, sans-serif`,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#B2B2B2',
            transform: 'translate(20px, 16px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.85)',
        },
        '& .MuiInputLabel-outlined.Mui-disabled': {
            color: '#E2E2EA',
        },
        '& label.Mui-error': {
            color: '#f44336',
        },
        '&:hover label.Mui-error': {
            color: '#00923A',
        },
        '& label.Mui-focused.Mui-error': {
            color: '#00923A',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline ': {
            borderColor: '#f5f5f5',
            color: '#E2E2EA',
            '& fieldset': {
                borderColor: '#f5f5f5',
            },
            '&:hover fieldset': {
                borderColor: '#f5f5f5',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#f5f5f5',
            },
        },
        '@media (max-width: 768px)': {
            width: '100%',
            maxWidth: '516px',
        },
    },
})(TextField);

const OutlinedInput = ({
    label,
    type,
    value,
    onChange,
    disabled,
    error,
    propsStyle,
    ...props
}) => {
    const onChangeField = (e) => {
        const val = e.target.value;
        onChange && onChange(val, type);
    };

    return (
        <GreenTextField
            {...props}
            onChange={onChangeField}
            label={label}
            value={value || ''}
            disabled={disabled}
            variant="outlined"
            size="small"
            error={error}
            type={type}
            style={propsStyle}
        />
    );
};

OutlinedInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    propsStyle: PropTypes.object,
};

OutlinedInput.defaultProps = {
    label: '',
    type: '',
    value: '',
    disabled: false,
    error: false,
    propsStyle: {},
};

export default OutlinedInput;
