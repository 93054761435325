import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_FUNCTIONS_LIST_START,
    GET_FUNCTIONS_LIST_SUCCESS,
    GET_FUNCTIONS_LIST_FAILURE,
    GET_CATEGORIES_LIST_START,
    GET_CATEGORIES_LIST_SUCCESS,
    GET_CATEGORIES_LIST_FAILURE,
    GET_HIERARCHICAL_CATEGORIES_LIST_START,
    GET_HIERARCHICAL_CATEGORIES_LIST_SUCCESS,
    GET_HIERARCHICAL_CATEGORIES_LIST_FAILURE,
    GET_CATEGORIES_WITH_ARTICLES_START,
    GET_CATEGORIES_WITH_ARTICLES_SUCCESS,
    GET_CATEGORIES_WITH_ARTICLES_FAILURE,
    GET_ENTERPRISE_STRUCTURE_START,
    GET_ENTERPRISE_STRUCTURE_SUCCESS,
    GET_ENTERPRISE_STRUCTURE_FAILED,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetHierarchicalCategoriesList(action) {
    try {
        const filter = action.payload;
        let url = `${API_DOMAIN}/dictionaries/categories/?page_size=1000&page=1`;
        if (filter) {
            url += `&filter[type]=${filter}`;
        }
        const data = yield call(request.get, url);
        return yield put({
            type: GET_HIERARCHICAL_CATEGORIES_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        return yield put({
            type: GET_HIERARCHICAL_CATEGORIES_LIST_FAILURE,
            errorMessage: error?.message,
        });
    }
}

function* getCategoriesWithArticles(action) {
    try {
        const article_type = action.payload?.article_type;
        const category_id = action.payload?.category_id;
        let url = `${API_DOMAIN}/dictionaries/categories/article_tree/`;
        if (article_type) {
            url += `?article_type=${article_type}`;
        }
        if (category_id) {
            url += url.includes('?') ? '&' : '?';
            url += `category_id=${category_id}`;
        }
        const data = yield call(request.get, url);
        return yield put({
            type: GET_CATEGORIES_WITH_ARTICLES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        return yield put({
            type: GET_CATEGORIES_WITH_ARTICLES_FAILURE,
            errorMessage: error?.message,
        });
    }
}

function* handleGetFlatCategoriesList() {
    try {
        const url = `${API_DOMAIN}/dictionaries/categories/flat/?page_size=1000&page=1`;
        const { results } = yield call(request.get, url);
        const ids = results.map((x) => x.id);
        const byIds = results.reduce(
            (acc, x) => Object.assign(acc, { [x.id]: x }),
            {},
        );
        return yield put({
            type: GET_CATEGORIES_LIST_SUCCESS,
            payload: { ids, byIds },
        });
    } catch (error) {
        return yield put({
            type: GET_CATEGORIES_LIST_FAILURE,
            errorMessage: error?.message,
        });
    }
}

function* handleGetFunctionsList() {
    try {
        const url = `${API_DOMAIN}/dictionaries/functions/?page_size=1000&page=1`;
        const { results } = yield call(request.get, url);
        const ids = results.map((x) => x.id);
        const byIds = results.reduce(
            (acc, x) => Object.assign(acc, { [x.id]: x }),
            {},
        );
        return yield put({
            type: GET_FUNCTIONS_LIST_SUCCESS,
            payload: { ids, byIds },
        });
    } catch (error) {
        return yield put({
            type: GET_FUNCTIONS_LIST_FAILURE,
            errorMessage: error?.message,
        });
    }
}

function* handleGetEnterpriseStructure(action) {
    try {
        const { filter } = action.payload;
        let url = `${API_DOMAIN}/sap_hr/enterprise_structure/?`;
        if (filter.type) {
            url += `type=${filter.type}&`;
        }
        url = url.slice(0, -1);

        const data = yield call(request.get, url);
        return yield put({
            type: GET_ENTERPRISE_STRUCTURE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        return yield put({
            type: GET_ENTERPRISE_STRUCTURE_FAILED,
            errorMessage: error?.message,
        });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_FUNCTIONS_LIST_START, handleGetFunctionsList);
    yield takeLatest(GET_CATEGORIES_LIST_START, handleGetFlatCategoriesList);
    yield takeLatest(
        GET_HIERARCHICAL_CATEGORIES_LIST_START,
        handleGetHierarchicalCategoriesList,
    );
    yield takeLatest(
        GET_CATEGORIES_WITH_ARTICLES_START,
        getCategoriesWithArticles,
    );
    yield takeLatest(
        GET_ENTERPRISE_STRUCTURE_START,
        handleGetEnterpriseStructure,
    );
}
