import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    CREATE_CATEGORY_START,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILED,
    GET_CATEGORY_DETAILS_START,
    GET_CATEGORY_DETAILS_SUCCESS,
    GET_CATEGORY_DETAILS_FAILED,
    GET_CATEGORY_EDIT_DETAILS_START,
    GET_CATEGORY_EDIT_DETAILS_SUCCESS,
    GET_CATEGORY_EDIT_DETAILS_FAILED,
    UPDATE_CATEGORY_START,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILED,
    DELETE_CATEGORY_START,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILED,
} from '../actions/actionTypes';

function* handleCreateCategory(action) {
    try {
        const url = `${API_DOMAIN}/dictionaries/categories/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({ type: CREATE_CATEGORY_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_CATEGORY_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_CATEGORY_FAILED, error });
    }
}

function* handleGetCategoryDetails(action) {
    try {
        const url = `${API_DOMAIN}/dictionaries/categories/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_CATEGORY_DETAILS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_CATEGORY_DETAILS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_CATEGORY_DETAILS_FAILED, error });
    }
}

function* handleGetCategoryEditDetails(action) {
    try {
        const url = `${API_DOMAIN}/dictionaries/categories/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_CATEGORY_EDIT_DETAILS_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: GET_CATEGORY_EDIT_DETAILS_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: GET_CATEGORY_EDIT_DETAILS_FAILED, error });
    }
}

function* handleUpdateCategory(action) {
    try {
        const url = `${API_DOMAIN}/dictionaries/categories/${action.payload.id}/`;
        const data = yield call(request.put, url, action.payload.item);
        if (data) {
            return yield put({ type: UPDATE_CATEGORY_SUCCESS, payload: data });
        }
        return yield put({ type: UPDATE_CATEGORY_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_CATEGORY_FAILED, error });
    }
}

function* handleDeleteCategory(action) {
    try {
        const { id, cb } = action.payload;
        const url = `${API_DOMAIN}/dictionaries/categories/${id}/`;
        const data = yield call(request.delete, url);
        if (data) {
            cb && cb();
            return yield put({
                type: DELETE_CATEGORY_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: DELETE_CATEGORY_FAILED, error: data });
    } catch (error) {
        return yield put({ type: DELETE_CATEGORY_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(CREATE_CATEGORY_START, handleCreateCategory);
    yield takeLatest(GET_CATEGORY_DETAILS_START, handleGetCategoryDetails);
    yield takeLatest(
        GET_CATEGORY_EDIT_DETAILS_START,
        handleGetCategoryEditDetails,
    );
    yield takeLatest(UPDATE_CATEGORY_START, handleUpdateCategory);
    yield takeLatest(DELETE_CATEGORY_START, handleDeleteCategory);
}
