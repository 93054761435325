import {
    GET_NEWS_DETAILS_START,
    GET_NEWS_DETAILS_SUCCESS,
    GET_NEWS_DETAILS_FAILED,
    CREATE_NEWS_START,
    CREATE_NEWS_SUCCESS,
    CREATE_NEWS_FAILED,
    UPDATE_NEWS_START,
    UPDATE_NEWS_SUCCESS,
    UPDATE_NEWS_FAILED,
    DELETE_NEWS_START,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILED,
    CLEAR_NEWS_DETAILS,
} from '../actions/actionTypes';

const initialState = {
    newsDetails: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const newsEditReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_NEWS_DETAILS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_NEWS_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                newsDetails: payload || {},
            };
        case GET_NEWS_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения новости',
            };
        case CREATE_NEWS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_NEWS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case CREATE_NEWS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания новости',
            };
        case UPDATE_NEWS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_NEWS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_NEWS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка сохранения новости',
            };
        case DELETE_NEWS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case DELETE_NEWS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                newsDetails: {},
            };
        case DELETE_NEWS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления новости',
            };
        case CLEAR_NEWS_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default newsEditReducer;
