import React from 'react';
import styles from './loading.module.scss';

function Loading() {
    return (
        <div className={styles.spinnerContainer}>
            <div className={styles.spinner}>
                <div className={styles.doubleBounce1} />
                <div className={styles.doubleBounce2} />
            </div>
        </div>
    );
}

export default Loading;
