import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_ARTICLES_DETAILS_START,
    GET_ARTICLES_DETAILS_SUCCESS,
    GET_ARTICLES_DETAILS_FAILED,
    CREATE_ARTICLE_START,
    CREATE_ARTICLE_SUCCESS,
    CREATE_ARTICLE_FAILED,
    UPDATE_ARTICLE_START,
    UPDATE_ARTICLE_SUCCESS,
    UPDATE_ARTICLE_FAILED,
    DELETE_ARTICLE_START,
    DELETE_ARTICLE_SUCCESS,
    DELETE_ARTICLE_FAILED,
    UPDATE_ARTICLE_DETAILS_START,
    UPDATE_ARTICLE_DETAILS_SUCCESS,
} from '../actions/actionTypes';

function* handleGetArticlesEdit(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ARTICLES_DETAILS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_ARTICLES_DETAILS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_ARTICLES_DETAILS_FAILED, error });
    }
}

function* handleCreateArticle(action) {
    try {
        const url = `${API_DOMAIN}/articles/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({ type: CREATE_ARTICLE_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_ARTICLE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_ARTICLE_FAILED, error });
    }
}

function* handleUpdateArticle(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload.lId}/`;
        const data = yield call(request.put, url, action.payload);
        if (data) {
            return yield put({ type: UPDATE_ARTICLE_SUCCESS, payload: data });
        }
        return yield put({ type: UPDATE_ARTICLE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_ARTICLE_FAILED, error });
    }
}

function* handleDeleteArticle(action) {
    try {
        const { id, cb } = action.payload;
        const url = `${API_DOMAIN}/articles/${id}/`;
        const data = yield call(request.delete, url);
        if (data) {
            cb && cb();
            return yield put({
                type: DELETE_ARTICLE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: DELETE_ARTICLE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: DELETE_ARTICLE_FAILED, error });
    }
}

function* handleUpdateArticleDetails(action) {
    return yield put({
        type: UPDATE_ARTICLE_DETAILS_SUCCESS,
        payload: action.payload,
    });
}

export default function* rootSaga() {
    yield takeEvery(GET_ARTICLES_DETAILS_START, handleGetArticlesEdit);
    yield takeEvery(CREATE_ARTICLE_START, handleCreateArticle);
    yield takeEvery(UPDATE_ARTICLE_START, handleUpdateArticle);
    yield takeLatest(DELETE_ARTICLE_START, handleDeleteArticle);
    yield takeEvery(UPDATE_ARTICLE_DETAILS_START, handleUpdateArticleDetails);
}
