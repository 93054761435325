import React from 'react';
import PropTypes from 'prop-types';
import styles from './buttonDefault.module.scss';

export const ButtonDefault = ({
    label,
    disabled,
    isCancelStyle,
    isDisableStyle,
    onClick,
    marginBottom,
    marginRight,
    marginTop,
    bigButton,
    extraTitle,
    greenHover,
}) => {
    let newClassName = isCancelStyle ? styles.cancelBtn : styles.approveBtn;
    newClassName =
        disabled || isDisableStyle ? styles.disabledBtn : newClassName;
    newClassName = `${styles.defaultBtn} ${newClassName} ${
        bigButton ? styles.bigButton : ''
    }`;
    if (!isCancelStyle && !disabled && !isDisableStyle && greenHover) {
        newClassName += ` ${styles.greenHover}`;
    }
    return (
        <button
            title={extraTitle ? extraTitle : ''}
            className={newClassName}
            style={{ marginBottom, marginRight, marginTop }}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

ButtonDefault.propTypes = {
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    disabled: PropTypes.bool,
    isCancelStyle: PropTypes.bool,
    isDisableStyle: PropTypes.bool,
    className: PropTypes.any,
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    bigButton: PropTypes.bool,
    extraTitle: PropTypes.string,
    greenHover: PropTypes.bool,
};

ButtonDefault.defaultProps = {
    label: null,
    disabled: false,
    isCancelStyle: false,
    isDisableStyle: false,
    className: null,
    marginBottom: 0,
    marginRight: 0,
    marginTop: 0,
    onClick: () => {},
    bigButton: false,
    extraTitle: '',
    greenHover: false,
};

export default ButtonDefault;
