import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_USERS_START,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetUsersList(action) {
    try {
        let url = `${API_DOMAIN}/users`;

        const data = yield call(request.get, url);
        return yield put({
            type: GET_USERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        return yield put({
            type: GET_USERS_FAILED,
            errorMessage: error?.message,
        });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_USERS_START, handleGetUsersList);
}
