import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    compose(applyMiddleware(sagaMiddleware), sentryReduxEnhancer),
);
sagaMiddleware.run(rootSaga);

export default store;
