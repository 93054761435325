import { UPDATE_NEWS_PAGE } from '../actions/actionTypes';

const initialState = {
    currentPage: 1,
};

const newsReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_NEWS_PAGE:
            return {
                ...state,
                currentPage: payload || {},
            };
        default:
            return state;
    }
};

export default newsReducer;
