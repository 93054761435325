import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_APPROVAL_DETAILS_START,
    GET_APPROVAL_DETAILS_SUCCESS,
    GET_APPROVAL_DETAILS_FAILED,
    GET_APPROVALS_COUNT_START,
    GET_APPROVALS_COUNT_SUCCESS,
    GET_APPROVALS_COUNT_FAILED,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetApprovalDetails(action) {
    try {
        const { filter } = action.payload;
        let url = `${API_DOMAIN}/approvals/for_approval/?page=1&page_size=1000`;
        if (filter.view) {
            url += `&view=${filter.view}`;
        }
        let data = yield call(request.get, url);
        if (filter.id && filter.view) {
            const filterField = {
                article: 'article_id',
                info: 'info_message_id',
            };
            data = data.results.filter(
                (r) => r[filterField[filter.view]] === parseInt(filter.id, 10),
            );
        }
        return yield put({
            type: GET_APPROVAL_DETAILS_SUCCESS,
            payload: { results: data },
        });
    } catch (error) {
        return yield put({
            type: GET_APPROVAL_DETAILS_FAILED,
            errorMessage: error?.message,
        });
    }
}

function* handleGetApprovalsCount(action) {
    try {
        const url =
            API_DOMAIN +
            `/approvals/for_approval/counts/?user_id=${action.payload}`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_APPROVALS_COUNT_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_APPROVALS_COUNT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_APPROVALS_COUNT_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_APPROVAL_DETAILS_START, handleGetApprovalDetails);
    yield takeLatest(GET_APPROVALS_COUNT_START, handleGetApprovalsCount);
}
