import { all, fork } from 'redux-saga/effects';
import watchMainSaga from './mainSaga';
import watchNewsEditSaga from './newsEditSaga';
import watchDictionariesSagas from './dictionariesSagas';
import watchCategoryEditSaga from './categoryEditSaga';
import watchLettersSaga from './lettersSaga';
import watchCommentsSaga from './commentsSaga';
import watchLettersEditSaga from './lettersEditSaga';
import watchArticlesSaga from './articlesSaga';
import watchArticlesEditSaga from './articlesEditSaga';
import watchFunctionEditSaga from './functionEditSaga';
import watchProfileSaga from './profileSaga';
import watchAccessRulesSaga from './accessRulesSaga';
import watchNotificationSaga from './notificationSaga';
import watchUsersEditSaga from './userEditSaga';
import watchAsuzEditSaga from './asuzEditSaga';
import watchUsersSaga from './usersSaga';
import watchQuestionsSaga from './questionsSaga';
import watchAgreementSaga from './agreementSaga';
import watchMediaSaga from './mediaSaga';
import watchDraftSaga from './draftSaga';
import watchScheduleEditSaga from './scheduleEditSaga';
import watchMyArticlesSaga from './myArticlesSaga';
import watchUnfinishedEditSaga from './unfinishedSaga';
import watchErrorsSaga from './errorsSaga';
import watchRequestSaga from './requestSaga';

export default function* rootSaga() {
    yield all([
        fork(watchMainSaga),
        fork(watchNewsEditSaga),
        fork(watchDictionariesSagas),
        fork(watchCategoryEditSaga),
        fork(watchLettersSaga),
        fork(watchCommentsSaga),
        fork(watchLettersEditSaga),
        fork(watchArticlesSaga),
        fork(watchArticlesEditSaga),
        fork(watchFunctionEditSaga),
        fork(watchProfileSaga),
        fork(watchAccessRulesSaga),
        fork(watchNotificationSaga),
        fork(watchUsersEditSaga),
        fork(watchAsuzEditSaga),
        fork(watchUsersSaga),
        fork(watchQuestionsSaga),
        fork(watchAgreementSaga),
        fork(watchMediaSaga),
        fork(watchDraftSaga),
        fork(watchScheduleEditSaga),
        fork(watchMyArticlesSaga),
        fork(watchUnfinishedEditSaga),
        fork(watchErrorsSaga),
        fork(watchRequestSaga),
    ]);
}
