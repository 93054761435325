import _ from 'lodash';
import {
    GET_ACCESS_RULES_START,
    GET_ACCESS_RULES_SUCCESS,
    GET_ACCESS_RULES_FAILED,
    SET_LOGIN_START,
    SET_LOGIN_SUCCESS,
    SET_LOGIN_FAILED,
    REMOVE_FIRST_LOGIN,
} from '../actions/actionTypes';
import { getFromStorage, setToStorage } from '../helpers/storage';

const initialState = {
    rules: getFromStorage('rules') || {},
    isFirstLogin: false,
    fetching: true,
    errorMessage: false,
};

const accessRulesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ACCESS_RULES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ACCESS_RULES_SUCCESS:
            const rules = _.keyBy(payload?.permissions);
            setToStorage('rules', rules);
            return {
                ...state,
                fetching: false,
                rules,
            };
        case GET_ACCESS_RULES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: payload,
            };
        case SET_LOGIN_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
                isFirstLogin: false,
            };
        case SET_LOGIN_SUCCESS:
            setToStorage('userName', payload?.user_profile?.fio);
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                isFirstLogin: !!payload?.is_first_login,
            };
        case SET_LOGIN_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: payload,
                isFirstLogin: false,
            };
        case REMOVE_FIRST_LOGIN:
            return {
                ...state,
                isFirstLogin: false,
            };
        default:
            return state;
    }
};

export default accessRulesReducer;
