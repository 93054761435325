import { combineReducers } from 'redux';
import {
    GET_CATEGORIES_LIST_START,
    GET_CATEGORIES_LIST_SUCCESS,
    GET_CATEGORIES_LIST_FAILURE,
    GET_FUNCTIONS_LIST_START,
    GET_FUNCTIONS_LIST_SUCCESS,
    GET_FUNCTIONS_LIST_FAILURE,
    GET_HIERARCHICAL_CATEGORIES_LIST_START,
    GET_HIERARCHICAL_CATEGORIES_LIST_SUCCESS,
    GET_HIERARCHICAL_CATEGORIES_LIST_FAILURE,
    GET_CATEGORIES_WITH_ARTICLES_START,
    GET_CATEGORIES_WITH_ARTICLES_SUCCESS,
    GET_CATEGORIES_WITH_ARTICLES_FAILURE,
    GET_ENTERPRISE_STRUCTURE_START,
    GET_ENTERPRISE_STRUCTURE_SUCCESS,
    GET_ENTERPRISE_STRUCTURE_FAILED,
} from '../actions/actionTypes';

const isFetching = (state = false, action) => {
    switch (action.type) {
        case GET_FUNCTIONS_LIST_START:
        case GET_CATEGORIES_LIST_START:
            return true;

        case GET_FUNCTIONS_LIST_FAILURE:
        case GET_FUNCTIONS_LIST_SUCCESS:
        case GET_CATEGORIES_LIST_FAILURE:
        case GET_CATEGORIES_LIST_SUCCESS:
            return false;

        default:
            return state;
    }
};

const initialState = {
    ids: [],
    byIds: {},
    errorMessage: '',
};

const functions = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_FUNCTIONS_LIST_START:
            return { ...state, errorMessage: initialState.errorMessage };

        case GET_FUNCTIONS_LIST_SUCCESS:
            return { ...state, ...payload };

        case GET_FUNCTIONS_LIST_FAILURE:
            return {
                ...state,
                ids: initialState.ids,
                byIds: initialState.byIds,
                errorMessage: payload?.errorMessage,
            };

        default:
            return state;
    }
};

const categories = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CATEGORIES_LIST_START:
            return { ...state, errorMessage: initialState.errorMessage };

        case GET_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                ...payload,
                errorMessage: initialState.errorMessage,
            };

        case GET_CATEGORIES_LIST_FAILURE:
            return {
                ...state,
                ids: initialState.ids,
                byIds: initialState.byIds,
                errorMessage: payload?.errorMessage,
            };

        default:
            return state;
    }
};

const hierarchicalCategories = (
    state = { data: [], errorMessage: '' },
    { type, payload },
) => {
    switch (type) {
        case GET_HIERARCHICAL_CATEGORIES_LIST_START:
            return { ...state, data: [], errorMessage: '' };

        case GET_HIERARCHICAL_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                data: payload,
                errorMessage: '',
            };

        case GET_HIERARCHICAL_CATEGORIES_LIST_FAILURE:
            return {
                ...state,
                data: [],
                errorMessage: payload?.errorMessage,
            };

        default:
            return state;
    }
};

const sortOrderArr = (data = []) => {
    return data.sort((a, b) => a.data?.sort_order - b.data?.sort_order);
};

const categoriesWithArticles = (
    state = { data: [], fetching: false, errorMessage: '' },
    { type, payload },
) => {
    switch (type) {
        case GET_CATEGORIES_WITH_ARTICLES_START:
            return { ...state, data: [], fetching: true, errorMessage: '' };

        case GET_CATEGORIES_WITH_ARTICLES_SUCCESS:
            const sorted = payload && payload[0] ? sortOrderArr(payload) : [];
            return {
                ...state,
                fetching: false,
                data: sorted,
                errorMessage: '',
            };

        case GET_CATEGORIES_WITH_ARTICLES_FAILURE:
            return {
                ...state,
                data: [],
                fetching: false,
                errorMessage: payload?.errorMessage,
            };

        default:
            return state;
    }
};

const enterpriseStructure = (
    state = { data: [], fetching: false, errorMessage: false },
    { type, payload },
) => {
    switch (type) {
        case GET_ENTERPRISE_STRUCTURE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ENTERPRISE_STRUCTURE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload.results,
            };
        case GET_ENTERPRISE_STRUCTURE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    (payload && payload?.message) ||
                    'Ошибка получения оргструктуры',
            };
        default:
            return state;
    }
};

export default combineReducers({
    isFetching,
    categories,
    hierarchicalCategories,
    categoriesWithArticles,
    functions,
    enterpriseStructure,
});
