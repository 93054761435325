import {
    GET_ACCESS_RULES_START,
    SET_LOGIN_START,
    REMOVE_FIRST_LOGIN,
} from './actionTypes';

export const getAccessRules = (payload) => ({
    type: GET_ACCESS_RULES_START,
    payload,
});

export const postSetLogin = (payload) => ({
    type: SET_LOGIN_START,
    payload,
});

export const removeFirstLogin = (payload) => ({
    type: REMOVE_FIRST_LOGIN,
    payload,
});
