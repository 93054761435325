import {
    GET_REQUEST_START,
    GET_REQUEST_SUCCESS,
    GET_REQUEST_FAILED,
    CREATE_REQUEST_START,
    CREATE_REQUEST_SUCCESS,
    CREATE_REQUEST_FAILED,
    UPDATE_REQUEST_START,
    UPDATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_FAILED,
    UPDATE_REQUEST_PAGE,
    CLEAR_REQUEST_DATA,
} from '../actions/actionTypes';

const initialState = {
    currentPage: 1,
    requestCount: 0,
    data: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const requestReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_REQUEST_PAGE:
            return {
                ...state,
                currentPage: payload || {},
            };
        case GET_REQUEST_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_REQUEST_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload || {},
            };
        case GET_REQUEST_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения запроса',
            };
        case CREATE_REQUEST_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_REQUEST_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case CREATE_REQUEST_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания запроса',
            };
        case UPDATE_REQUEST_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_REQUEST_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка обновления запроса',
            };
        case CLEAR_REQUEST_DATA:
            return initialState;
        default:
            return state;
    }
};

export default requestReducer;
