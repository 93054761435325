import User from './user';
import { API_DOMAIN, DIR_ERROR } from './constants';
import { getFromStorage, removeFromStorage, setToStorage } from './storage';
import notify from './notify';
import { checkIfTest } from './m';

const forceLogout = () => {
    if (checkIfTest()) {
        User.logout();
        return window.location.replace('/');
    }
    return redirectToLogout();
};

const checkToLogout = (response) => {
    if (
        !response ||
        (response &&
            response.status &&
            (response.status === 401 || response.status === 422))
    ) {
        // todo temp
        return forceLogout();
    }
    return true;
};

const responseIsOk = (res) => {
    return res && (res.status === 200 || res.status === 201);
};

const serverError = (res) => {
    return res?.status === 500;
};

const emptyResponse = (e) => {
    return e && e.message && e.message.includes('Unexpected end of JSON');
};

const getErrorMessage = (r) => {
    let errorMessage = '';
    const key = Object.keys(r)[0] ? Object.keys(r)[0] : null;
    if (r.message) {
        errorMessage = r.message;
    } else if (key) {
        const errorArr = r[key];
        errorMessage += key === 'non_field_errors' ? '' : `${key}: `;
        errorMessage +=
            errorArr && errorArr[0] && errorArr[0].message
                ? errorArr[0].message
                : JSON.stringify(errorArr);
    }
    return errorMessage;
};

const logoutDir = async (res) => {
    try {
        const r = await res.json();
        setTimeout(() => notify.error(r.message), 300);
        setToStorage('appShowError', r.message);
        forceLogout();
    } catch (e) {
        setToStorage('appShowError', DIR_ERROR);
        forceLogout();
    }
};

const initParams = {
    method: 'POST',
    cache: 'no-cache',
};

const updateToken = async () => {
    try {
        const headers = new Headers({
            Authorization: User.getRefreshHeader(),
        });
        const params = {
            ...initParams,
            headers,
        };
        const response = await fetch(`${API_DOMAIN}/auth/refresh`, params);
        checkToLogout(response);
        return await response.json();
    } catch (e) {
        // todo temp
        // return forceLogout();
    }
};

export const redirectToLogout = () => {
    const user = getFromStorage('user');
    const token = user && user.token;
    const refreshToken = User.getProdRefreshToken();
    const params = new URLSearchParams({
        token: refreshToken || token,
        token_type_hint: refreshToken ? 'refresh_token' : 'access_token',
        client_id: process.env.REACT_APP_CLIENT_ID || '',
        post_logout_redirect_uri: process.env.REACT_APP_KEYCLOAK_CALLBACK || '',
        ui_locales: window.navigator.languages.join(' '),
    });

    User.logout();

    window.location.replace(
        `${process.env.REACT_APP_KEYCLOAK_URL}realms/${
            process.env.REACT_APP_KEYCLOAK_REALM
        }/protocol/openid-connect/logout?${params.toString()}`,
    );
};

export const updateProdToken = async () => {
    try {
        const isTest = checkIfTest();
        const isRefreshing = getFromStorage('ROCP_tokenIsRefreshing');
        const tokenExpire = getFromStorage('ROCP_tokenExpire');
        const currentTime = parseInt((new Date().getTime() / 1000).toFixed());

        if (!tokenExpire || isRefreshing || isTest) return;
        if (tokenExpire - currentTime > 70) return;

        setToStorage('ROCP_tokenIsRefreshing', true);

        const response = await fetch(
            `${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
            {
                ...initParams,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    grant_type: 'refresh_token',
                    refresh_token: User.getProdRefreshToken(),
                    client_id: process.env.REACT_APP_CLIENT_ID,
                }),
            },
        );

        checkToLogout(response);
        if (response?.status === 400) {
            forceLogout();
            return;
        }
        const data = await response.json();
        if (data?.access_token) {
            setToStorage('user', { token: data?.access_token });
            setToStorage('ROCP_token', data?.access_token);
            setToStorage('ROCP_tokenExpire', currentTime + data?.expires_in);
            setToStorage(
                'ROCP_refreshTokenExpire',
                currentTime + data?.refresh_expires_in,
            );
            setToStorage('ROCP_refreshToken', data?.refresh_token);
        }

        removeFromStorage('ROCP_tokenIsRefreshing');

        return data;
    } catch (e) {
        console.error('update error', e);
        removeFromStorage('ROCP_tokenIsRefreshing');
        // todo temp
        // return forceLogout();
    }
};

export const checkForFailed = async () => {
    const failedArray = getFromStorage('failedQueue') || [];
    removeFromStorage('failedQueue');
    if (!failedArray?.length) return;

    for (const req of failedArray) {
        await tryFetch(req.url, req.data, true);
    }
};

const tryFetch = async (url = '', _init = {}, notFirstTime = false) => {
    try {
        const handleRes = async (res) => {
            try {
                checkToLogout(res);
                if (res?.status === 204) {
                    return {};
                }
                const r = await res.json();
                if (responseIsOk(res)) {
                    return r;
                }
                const mes = getErrorMessage(r);
                if (mes.includes('Unauthorized')) return {};
                throw new Error(mes);
            } catch (e) {
                if (emptyResponse(e) && responseIsOk(res)) {
                    return {};
                }
                if (serverError(res)) {
                    throw new Error(
                        'Ошибка: ' +
                            (res?.statusText || 'Internal Server Error'),
                    );
                }
                throw new Error(e);
            }
        };
        const headers = new Headers({});

        if (User.isLoggedIn()) {
            headers.append('Authorization', User.getHeader());
        }

        const { method, data, ...rest } = _init;
        let body = null;

        if (['POST', 'PUT', 'PATCH'].includes(method.toUpperCase()) && data) {
            if (data instanceof FormData) {
                body = data;
            } else {
                headers.append('Content-Type', 'application/json');
                body = JSON.stringify(data);
            }
        }

        const params = {
            ...rest,
            method,
            headers,
        };

        if (method !== 'GET') {
            params.body = body;
        }

        let resp = await fetch(url, params);

        if (resp?.status === 432) {
            return await logoutDir(resp);
        }

        if (resp.status && resp.status === 401) {
            if (checkIfTest()) {
                const data = await updateToken();
                const token = data.access_token;
                if (token) {
                    User.setToken(token);
                    headers.set('Authorization', User.getHeader());
                    resp = await fetch(url, { ...params, headers });
                    return await handleRes(resp);
                }
                return;
            }
            // const data = await updateProdToken();

            if (!notFirstTime) {
                const failedArray = getFromStorage('failedQueue') || [];
                setToStorage('failedQueue', [
                    ...failedArray,
                    { url, data: { ...params, headers } },
                ]);
                setTimeout(() => checkForFailed(), 4000);
                return;
            }

            // todo temp
            // forceLogout();
        }

        return await handleRes(resp);
    } catch (e) {
        notify.error(e.message);
        throw new Error(e.message);
    }
};

export const request = {
    async get(url = '', params = {}) {
        try {
            const lInit = {
                ...initParams,
                method: 'GET',
            };
            return await tryFetch(url, lInit);
        } catch (e) {
            throw new Error(e);
        }
    },

    async post(url = '', data = {}) {
        try {
            const lInit = {
                ...initParams,
                data,
            };

            return await tryFetch(url, lInit);
        } catch (e) {
            throw new Error(e);
        }
    },

    async put(url = '', data = {}) {
        try {
            const lInit = {
                ...initParams,
                method: 'PUT',
                data,
            };

            return await tryFetch(url, lInit);
        } catch (e) {
            throw new Error(e);
        }
    },

    async patch(url = '', data = '') {
        try {
            const lInit = {
                ...initParams,
                method: 'PATCH',
                data,
            };

            return await tryFetch(url, lInit);
        } catch (e) {
            throw e;
        }
    },

    async delete(url = '', data = {}) {
        try {
            const lInit = {
                ...initParams,
                method: 'DELETE',
                data,
            };

            return await tryFetch(url, lInit);
        } catch (e) {
            throw new Error(e.message);
        }
    },
};
