import {
    GET_ALL_DRAFT_SAVES_START,
    GET_ALL_DRAFT_SAVES_SUCCESS,
    GET_ALL_DRAFT_SAVES_FAILED,
    GET_DRAFT_SAVE_START,
    GET_DRAFT_SAVE_SUCCESS,
    GET_DRAFT_SAVE_FAILED,
    CREATE_DRAFT_SAVE_START,
    CREATE_DRAFT_SAVE_SUCCESS,
    CREATE_DRAFT_SAVE_FAILED,
    UPDATE_DRAFT_SAVE_START,
    UPDATE_DRAFT_SAVE_SUCCESS,
    UPDATE_DRAFT_SAVE_FAILED,
    DELETE_DRAFT_SAVE_START,
    DELETE_DRAFT_SAVE_SUCCESS,
    DELETE_DRAFT_SAVE_FAILED,
    UPDATE_DRAFT_SAVE_ID,
    CLEAR_DRAFT_SAVE,
} from '../actions/actionTypes';

const initialState = {
    currentPage: 1,
    currentId: null,
    data: [],
    draftDetails: {},
    fetching: false,
    errorMessage: false,
};

const draftReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_ALL_DRAFT_SAVES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ALL_DRAFT_SAVES_SUCCESS:
            return {
                ...state,
                data: payload || [],
                fetching: false,
                errorMessage: false,
            };
        case GET_ALL_DRAFT_SAVES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения автосохранений',
            };
        case GET_DRAFT_SAVE_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_DRAFT_SAVE_SUCCESS:
            const draft = (payload?.results || [])[0] || {};
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                currentId: draft?.id ?? null,
                draftDetails: draft,
            };
        case GET_DRAFT_SAVE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения автосохранения',
            };
        case CREATE_DRAFT_SAVE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_DRAFT_SAVE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                currentId: payload.id,
            };
        case CREATE_DRAFT_SAVE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка создания автосохранения',
            };
        case UPDATE_DRAFT_SAVE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_DRAFT_SAVE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case UPDATE_DRAFT_SAVE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка обновления автосохранения',
            };
        case DELETE_DRAFT_SAVE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case DELETE_DRAFT_SAVE_SUCCESS:
            return {
                ...state,
                fetching: false,
                draftDetails: {},
                currentId: null,
                errorMessage: false,
            };
        case DELETE_DRAFT_SAVE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления категории',
            };
        case UPDATE_DRAFT_SAVE_ID:
            return {
                ...state,
                currentId: payload,
            };
        case CLEAR_DRAFT_SAVE:
            return initialState;
        default:
            return state;
    }
};

export default draftReducer;
