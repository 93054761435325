import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_UNFINISHED_EDIT_START,
    GET_UNFINISHED_EDIT_SUCCESS,
    GET_UNFINISHED_EDIT_FAILED,
    CREATE_UNFINISHED_EDIT_START,
    CREATE_UNFINISHED_EDIT_SUCCESS,
    CREATE_UNFINISHED_EDIT_FAILED,
    DELETE_UNFINISHED_EDIT_START,
    DELETE_UNFINISHED_EDIT_SUCCESS,
    DELETE_UNFINISHED_EDIT_FAILED,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetUnfinishedEdit(action) {
    try {
        const { type, id } = action.payload;
        const url = `${API_DOMAIN}/${type}/${id}/locked/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_UNFINISHED_EDIT_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_UNFINISHED_EDIT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_UNFINISHED_EDIT_FAILED, error });
    }
}

function* handleCreateUnfinishedEdit(action) {
    try {
        const { type, id } = action.payload;
        const url = `${API_DOMAIN}/${type}/${id}/locked/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({
                type: CREATE_UNFINISHED_EDIT_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: CREATE_UNFINISHED_EDIT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_UNFINISHED_EDIT_FAILED, error });
    }
}

function* handleDeleteUnfinishedEdit(action) {
    try {
        const { type, id, cb } = action.payload;
        const url = `${API_DOMAIN}/${type}/${id}/locked/`;
        const data = yield call(request.delete, url);
        if (data) {
            cb && cb();
            return yield put({
                type: DELETE_UNFINISHED_EDIT_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: DELETE_UNFINISHED_EDIT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: DELETE_UNFINISHED_EDIT_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_UNFINISHED_EDIT_START, handleGetUnfinishedEdit);
    yield takeLatest(CREATE_UNFINISHED_EDIT_START, handleCreateUnfinishedEdit);
    yield takeLatest(DELETE_UNFINISHED_EDIT_START, handleDeleteUnfinishedEdit);
}
