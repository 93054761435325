import {
    GET_LETTERS_DATES_START,
    GET_LETTERS_UNPUBLISHED_START,
    GET_LETTER_DETAILS_START,
    GET_LETTERS_LIST_BY_DATE_START,
    REMOVE_ITEM_FROM_LETTER_DATA_BY_ID,
    CLEAR_LETTER_DETAILS,
    SET_LETTER_FAVOURITE_START,
    REMOVE_LETTER_FAVOURITE_START,
    SET_LETTER_LIKE_START,
    REMOVE_LETTER_LIKE_START,
    SET_LETTER_DISLIKE_START,
    REMOVE_LETTER_DISLIKE_START,
    COPY_ONE_LETTER_START,
    ADD_TO_CHAIN_LETTER_START,
    MASS_REMOVE_LETTERS_START,
    GET_LETTERS_CHAIN_LIST_START,
    UPDATE_LETTER_STATUS,
    CHANGE_LETTER_START,
    GET_LETTERS_LIST_START,
    SET_EMPTY_LETTERS_LIST,
} from './actionTypes';
import { getLetterStatusAction } from '../helpers/m';

export const getLettersDates = (payload) => ({
    type: GET_LETTERS_DATES_START,
    payload,
});

export const getUnpublishedCount = () => ({
    type: GET_LETTERS_UNPUBLISHED_START,
});

export const getLetterDetails = (payload) => ({
    type: GET_LETTER_DETAILS_START,
    payload,
});

export const getLettersChainList = (payload) => ({
    type: GET_LETTERS_CHAIN_LIST_START,
    payload,
});

export const getLettersListByDate = (payload) => ({
    type: GET_LETTERS_LIST_BY_DATE_START,
    payload,
});

export const removeItemFromLetterDataById = (payload) => ({
    type: REMOVE_ITEM_FROM_LETTER_DATA_BY_ID,
    payload,
});

export const setLetterFavourite = (payload) => ({
    type: SET_LETTER_FAVOURITE_START,
    payload,
});

export const removeLetterFavourite = (payload) => ({
    type: REMOVE_LETTER_FAVOURITE_START,
    payload,
});

export const setLetterLike = (payload) => ({
    type: SET_LETTER_LIKE_START,
    payload,
});

export const removeLetterLike = (payload) => ({
    type: REMOVE_LETTER_LIKE_START,
    payload,
});

export const setLetterDislike = (payload) => ({
    type: SET_LETTER_DISLIKE_START,
    payload,
});

export const removeLetterDislike = (payload) => ({
    type: REMOVE_LETTER_DISLIKE_START,
    payload,
});

export const copyOneMail = (payload) => ({
    type: COPY_ONE_LETTER_START,
    payload,
});

export const addToChainMail = (payload) => ({
    type: ADD_TO_CHAIN_LETTER_START,
    payload,
});

export const massRemoveLetters = (payload) => ({
    type: MASS_REMOVE_LETTERS_START,
    payload,
});

export const changeLetterStatus = (payload) => {
    const { id, status, cb, obj } = payload;
    const data = getLetterStatusAction(status, id);
    const { url, notify } = data;
    return {
        type: CHANGE_LETTER_START,
        payload: {
            url: url,
            data: obj || null,
            cb: cb,
            notifyText: notify || null,
        },
    };
};

export const updateLetterStatus = (payload) => ({
    type: UPDATE_LETTER_STATUS,
    payload,
});

export const clearLetterDetails = () => ({ type: CLEAR_LETTER_DETAILS });

export const getLettersList = (payload) => ({
    type: GET_LETTERS_LIST_START,
    payload,
});

export const setEmptyLettersList = (payload) => ({
    type: SET_EMPTY_LETTERS_LIST,
    payload,
});
