import React, { useState } from 'react';
import { getFromStorage, setToStorage } from '../../../helpers/storage';
import { ReactComponent as CloseIcon } from '../../../assets/icons/grayX.svg';
import styles from './cookiesModal.module.scss';

const CookiesModal = () => {
    const hasKey = 'cookiesModalAccepted' in localStorage;
    const accepted = hasKey ? getFromStorage('cookiesModalAccepted') : false;
    const [showModal, setShowModal] = useState(!accepted);

    const onClickClose = () => {
        setToStorage('cookiesModalAccepted', true);
        setShowModal(false);
    };

    return showModal ? (
        <div className={styles.cookiesModalContainer}>
            <p>
                Данный сайт использует файлы-cookies, а также другие технологии
                для улучшения работы, совершенствования сервисов, определения
                пользовательских предпочтений и обеспечения безопасности.
                Продолжая работу с сайтом, Вы даете согласие на обработку
                файлов-cookies в соответствии с{' '}
                <a
                    href="https://media.5ka.ru/media/hosting/documents/b89ab324-a666-4388-92d6-1ba37404c24b.pdf"
                    target="_blank"
                    className="btn"
                    rel="noreferrer"
                >
                    Политикой в отношении обработки персональных данных
                </a>{' '}
                . При несогласии Вы можете отключить обработку файлов-cookies в
                настройках Вашего браузера или закрыть страницу сайта.
            </p>
            <CloseIcon onClick={onClickClose} className={styles.closeIcon} />
        </div>
    ) : null;
};

export default CookiesModal;
