import React from 'react';
import * as Sentry from '@sentry/browser';
import ButtonDefault from '../ButtonDefault';
import styles from './errorBoundary.module.scss';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: '',
            errorInfo: '',
            hasError: false,
        };
        this.reloadPage = this.reloadPage.bind(this);
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId, errorInfo });
        });
    }

    reloadPage() {
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.errorContainer}>
                    <div className={styles.errorInner}>
                        <h1 className={styles.errorTitle}>
                            Что-то пошло не так
                        </h1>
                        <ButtonDefault
                            bigButton={true}
                            label="Обновить страницу"
                            onClick={this.reloadPage}
                        />
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
