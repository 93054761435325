import {
    GET_USER_NOTIFICATIONS_START,
    SET_USER_NOTIFICATIONS_READ_START,
    SET_USER_NOTIFICATIONS_READ_ALL_START,
    CLEAR_USER_NOTIFICATIONS,
} from './actionTypes';

export const getUserNotifications = () => ({
    type: GET_USER_NOTIFICATIONS_START,
});

export const setUserNotificationsRead = (payload) => ({
    type: SET_USER_NOTIFICATIONS_READ_START,
    payload,
});

export const setUserNotificationsReadAll = (payload) => ({
    type: SET_USER_NOTIFICATIONS_READ_ALL_START,
    payload,
});

export const clearUserNotifications = () => ({
    type: CLEAR_USER_NOTIFICATIONS,
});
