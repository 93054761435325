import {
    GET_ARTICLES_DATES_START,
    GET_ARTICLE_DETAILS_START,
    GET_ARTICLE_DELETED_DETAILS_START,
    GET_ARTICLES_LIST_BY_DATE_START,
    REMOVE_ITEM_FROM_ARTICLE_DATA_BY_ID,
    CLEAR_ARTICLE_DETAILS,
    SET_ARTICLE_FAVOURITE_START,
    REMOVE_ARTICLE_FAVOURITE_START,
    SET_ARTICLE_LIKE_START,
    REMOVE_ARTICLE_LIKE_START,
    SET_ARTICLE_DISLIKE_START,
    REMOVE_ARTICLE_DISLIKE_START,
    MASS_REMOVE_ARTICLES_START,
    CHANGE_ARTICLES_START,
    RECOVER_DELETED_ARTICLE_START,
    ERASE_DELETED_ARTICLE_START,
    UPDATE_ARTICLE_STATUS,
    GET_ARTICLES_LIST_START,
    SET_EMPTY_ARTICLES_LIST,
} from './actionTypes';
import { getStatusAction } from '../helpers/m';

export const getArticlesDates = (payload) => ({
    type: GET_ARTICLES_DATES_START,
    payload,
});

export const getArticleDetails = (payload) => ({
    type: GET_ARTICLE_DETAILS_START,
    payload,
});

export const getArticleDeletedDetails = (payload) => ({
    type: GET_ARTICLE_DELETED_DETAILS_START,
    payload,
});

export const getArticlesListByDate = (payload) => ({
    type: GET_ARTICLES_LIST_BY_DATE_START,
    payload,
});

export const removeItemFromArticleDataById = (payload) => ({
    type: REMOVE_ITEM_FROM_ARTICLE_DATA_BY_ID,
    payload,
});

export const setArticleFavourite = (payload) => ({
    type: SET_ARTICLE_FAVOURITE_START,
    payload,
});

export const removeArticleFavourite = (payload) => ({
    type: REMOVE_ARTICLE_FAVOURITE_START,
    payload,
});

export const setArticleLike = (payload) => ({
    type: SET_ARTICLE_LIKE_START,
    payload,
});

export const removeArticleLike = (payload) => ({
    type: REMOVE_ARTICLE_LIKE_START,
    payload,
});

export const setArticleDislike = (payload) => ({
    type: SET_ARTICLE_DISLIKE_START,
    payload,
});

export const removeArticleDislike = (payload) => ({
    type: REMOVE_ARTICLE_DISLIKE_START,
    payload,
});

export const massRemoveArticles = (payload) => ({
    type: MASS_REMOVE_ARTICLES_START,
    payload,
});

export const changeArticleStatus = (payload) => {
    const { id, status, cb, obj } = payload;
    const data = getStatusAction(status, id);
    const { url, notify } = data;
    return {
        type: CHANGE_ARTICLES_START,
        payload: {
            url: url,
            data: obj || null,
            cb: cb,
            notifyText: notify || null,
        },
    };
};

export const updateArticleStatus = (payload) => ({
    type: UPDATE_ARTICLE_STATUS,
    payload,
});

export const recoverDeletedArticle = (payload) => ({
    type: RECOVER_DELETED_ARTICLE_START,
    payload,
});

export const eraseDeletedArticle = (payload) => ({
    type: ERASE_DELETED_ARTICLE_START,
    payload,
});

export const clearArticleDetails = () => ({ type: CLEAR_ARTICLE_DETAILS });

export const getArticlesList = (payload) => ({
    type: GET_ARTICLES_LIST_START,
    payload,
});

export const setEmptyArticlesList = (payload) => ({
    type: SET_EMPTY_ARTICLES_LIST,
});
