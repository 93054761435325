import {
    UPDATE_MEDIA_LIST,
    GET_MEDIA_START,
    GET_MEDIA_LIST_START,
    SET_EMPTY_MEDIA_LIST,
} from './actionTypes';

export const updateMediaList = (payload) => ({
    type: UPDATE_MEDIA_LIST,
    payload,
});

export const getMediaList = (payload) => ({
    type: GET_MEDIA_START,
    payload,
});

export const getMediaAttachmentsList = (payload) => ({
    type: GET_MEDIA_LIST_START,
    payload,
});

export const setEmptyMediaAttachmentsList = (payload) => ({
    type: SET_EMPTY_MEDIA_LIST,
});
