import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_DOMAIN } from '../helpers/constants';
import { request } from '../helpers/api';
import {
    GET_ERROR_DATA_FAILED,
    GET_ERROR_DATA_START,
    GET_ERROR_DATA_SUCCESS,
    GET_ERROR_NOTIFIES_FAILED,
    GET_ERROR_NOTIFIES_START,
    GET_ERROR_NOTIFIES_SUCCESS,
} from '../actions/actionTypes';

function* handleGetErrorData(action) {
    try {
        const url = `${API_DOMAIN}/bug_reports/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ERROR_DATA_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: GET_ERROR_DATA_FAILED,
            payload: data,
        });
    } catch (error) {
        return yield put({
            type: GET_ERROR_DATA_FAILED,
            errorMessage: error?.message,
        });
    }
}

function* handleGetErrorNotifies() {
    try {
        const url = `${API_DOMAIN}/bug_reports/count/?is_processed=false`;
        const data = yield call(request.get, url);
        if (data && data.total) {
            return yield put({
                type: GET_ERROR_NOTIFIES_SUCCESS,
                payload: data.total || 0,
            });
        }
        return yield put({ type: GET_ERROR_NOTIFIES_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_ERROR_NOTIFIES_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_ERROR_DATA_START, handleGetErrorData);
    yield takeLatest(GET_ERROR_NOTIFIES_START, handleGetErrorNotifies);
}
