import {
    GET_SCHEDULE_FAILED,
    GET_SCHEDULE_START,
    GET_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_START,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILED,
    UPDATE_SCHEDULE_START,
    UPDATE_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_FAILED,
    CLEAR_SCHEDULE_DETAILS,
} from '../actions/actionTypes';

const initialState = {
    data: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const scheduleEditReducer = (
    state = initialState,
    { type, payload, error },
) => {
    switch (type) {
        case GET_SCHEDULE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_SCHEDULE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload || {},
            };
        case GET_SCHEDULE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения расписания',
            };
        case CREATE_SCHEDULE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case CREATE_SCHEDULE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания расписания',
            };
        case UPDATE_SCHEDULE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_SCHEDULE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка обновления расписания',
            };
        case CLEAR_SCHEDULE_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default scheduleEditReducer;
