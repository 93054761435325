import { call, put, takeEvery } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_PROFILE_INFO_START,
    GET_PROFILE_INFO_SUCCESS,
    GET_PROFILE_INFO_FAILED,
    UPDATE_PROFILE_SUBSCRIPTIONS_START,
    UPDATE_PROFILE_SUBSCRIPTIONS_SUCCESS,
    UPDATE_PROFILE_SUBSCRIPTIONS_FAILED,
    UPDATE_PROFILE_INFO_START,
    UPDATE_PROFILE_INFO_SUCCESS,
    UPDATE_PROFILE_INFO_FAILED,
} from '../actions/actionTypes';
import notify from '../helpers/notify';

function* handleGetProfileInfo() {
    try {
        const url = `${API_DOMAIN}/users/profile/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({ type: GET_PROFILE_INFO_SUCCESS, payload: data });
        }
        return yield put({ type: GET_PROFILE_INFO_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_PROFILE_INFO_FAILED, error });
    }
}

function* handleUpdateProfileSubscriptions(action) {
    try {
        const url = `${API_DOMAIN}/users/profile/`;
        const data = yield call(request.patch, url, action.payload);
        if (data) {
            notify.success('Подписки успешно обновлены');
            return yield put({
                type: UPDATE_PROFILE_SUBSCRIPTIONS_SUCCESS,
                payload: action.payload,
            });
        }
        return yield put({
            type: UPDATE_PROFILE_SUBSCRIPTIONS_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: UPDATE_PROFILE_SUBSCRIPTIONS_FAILED, error });
    }
}

function* handleUpdateProfileInfo(action) {
    try {
        const url = `${API_DOMAIN}/users/profile/`;
        const data = yield call(request.put, url, action.payload);
        if (data) {
            notify.success('Профиль успешно обновлен');
            return yield put({
                type: UPDATE_PROFILE_INFO_SUCCESS,
                payload: action.payload.localItem,
            });
        }
        return yield put({
            type: UPDATE_PROFILE_INFO_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: UPDATE_PROFILE_INFO_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_PROFILE_INFO_START, handleGetProfileInfo);
    yield takeEvery(
        UPDATE_PROFILE_SUBSCRIPTIONS_START,
        handleUpdateProfileSubscriptions,
    );
    yield takeEvery(UPDATE_PROFILE_INFO_START, handleUpdateProfileInfo);
}
