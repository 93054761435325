import {
    GET_UNFINISHED_EDIT_START,
    GET_UNFINISHED_EDIT_SUCCESS,
    GET_UNFINISHED_EDIT_FAILED,
    CREATE_UNFINISHED_EDIT_START,
    CREATE_UNFINISHED_EDIT_SUCCESS,
    CREATE_UNFINISHED_EDIT_FAILED,
    DELETE_UNFINISHED_EDIT_START,
    DELETE_UNFINISHED_EDIT_SUCCESS,
    DELETE_UNFINISHED_EDIT_FAILED,
    CLEAR_UNFINISHED_EDIT,
} from '../actions/actionTypes';

const initialState = {
    unfinishedDetails: {},
    unfinishedStarted: false,
    fetching: false,
    errorMessage: false,
};

const unfinishedReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_UNFINISHED_EDIT_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_UNFINISHED_EDIT_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                unfinishedDetails: {
                    ...(payload || {}),
                    loaded: true,
                    loadedEmpty: !payload.create_dt,
                },
            };
        case GET_UNFINISHED_EDIT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения редактирования',
            };
        case CREATE_UNFINISHED_EDIT_START:
            return {
                ...state,
                fetching: true,
                unfinishedStarted: true,
                errorMessage: false,
            };
        case CREATE_UNFINISHED_EDIT_SUCCESS:
            return {
                ...state,
                fetching: false,
                unfinishedStarted: true,
                errorMessage: false,
            };
        case CREATE_UNFINISHED_EDIT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка создания редактирования',
            };
        case DELETE_UNFINISHED_EDIT_START:
            return {
                ...state,
                fetching: true,
                unfinishedStarted: false,
                errorMessage: false,
            };
        case DELETE_UNFINISHED_EDIT_SUCCESS:
            return {
                ...state,
                fetching: false,
                unfinishedStarted: false,
                errorMessage: false,
            };
        case DELETE_UNFINISHED_EDIT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка удаления редактирования',
            };
        case CLEAR_UNFINISHED_EDIT:
            return initialState;
        default:
            return state;
    }
};

export default unfinishedReducer;
