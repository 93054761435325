import store from '../store';
import {
    errorMessageUpdate,
    successMessageUpdate,
    warningMessageUpdate,
} from '../actions/mainAction';

const notify = {
    error: function (msg) {
        store.dispatch(errorMessageUpdate(msg));
    },
    success: function (msg) {
        store.dispatch(successMessageUpdate(msg));
    },
    warning: function (msg) {
        store.dispatch(warningMessageUpdate(msg));
    },
};

export default notify;
