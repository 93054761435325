import MainContainer from '../containers/main';
import SignInComponent from '../pages/signIn';
import AuthCallbackComponent from '../components/CommonParts/AuthCallback';

export const DEFAULT_ROUTE = '/main';

export const LOGIN_ROUTE = '/login';

export const ROUTES = [
    {
        path: '/auth/callback',
        component: AuthCallbackComponent,
        exact: true,
    },
    {
        path: '/login',
        component: SignInComponent,
    },
    {
        path: '/',
        component: MainContainer,
        private: true,
    },
];
