import {
    GET_COMMENTS_START,
    GET_COMMENTS_SUCCESS,
    GET_COMMENTS_FAILED,
    GET_ADMIN_COMMENTS_START,
    GET_ADMIN_COMMENTS_SUCCESS,
    GET_ADMIN_COMMENTS_FAILED,
    CREATE_COMMENT_START,
    CREATE_COMMENT_SUCCESS,
    CREATE_COMMENT_FAILED,
    UPDATE_COMMENT_START,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_FAILED,
    DELETE_COMMENT_START,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILED,
    CREATE_ADMIN_COMMENT_START,
    CREATE_ADMIN_COMMENT_SUCCESS,
    CREATE_ADMIN_COMMENT_FAILED,
    UPDATE_ADMIN_COMMENT_START,
    UPDATE_ADMIN_COMMENT_SUCCESS,
    UPDATE_ADMIN_COMMENT_FAILED,
    DELETE_ADMIN_COMMENT_START,
    DELETE_ADMIN_COMMENT_SUCCESS,
    DELETE_ADMIN_COMMENT_FAILED,
    CLEAR_COMMENTS_DETAILS,
    CLEAR_ADMIN_COMMENTS_DETAILS,
} from '../actions/actionTypes';

const initCommentsState = {
    commentsList: [],
    commentsCount: 0,
    pageCount: 1,
};

const initAdminCommentsState = {
    adminCommentsList: [],
    adminCommentsCount: 0,
    adminPageCount: 1,
};

const initialState = {
    ...initCommentsState,
    ...initAdminCommentsState,
    fetching: false,
    errorMessage: false,
};

const addToComments = (list, data, count) => {
    let newArr = list || [];
    let newCount = count || 0;
    const parent = data?.parent || data?.parent_id;
    if (parent) {
        newArr = newArr.map((el) => {
            let obj = el;
            if (obj.id === parent) {
                obj.comments = obj.comments || [];
                obj.comments.push(data);
            }
            return obj;
        });
    } else {
        newCount++;
        newArr.unshift(data);
    }
    return { list: newArr, count: newCount };
};

const updateInComments = (list, data) => {
    let newArr = list || [];
    const parent = data?.parent || data?.parent_id;
    if (parent) {
        newArr = newArr.map((el) => {
            let obj = el;
            if (obj.id === parent) {
                obj.comments = (obj.comments || []).map((it) => {
                    if (it.id === data.id) {
                        return data;
                    }
                    return it;
                });
            }
            return obj;
        });
    } else {
        newArr = newArr.map((it) => {
            if (it.id === data.id) {
                return data;
            }
            return it;
        });
    }
    return newArr;
};

const removeFromComments = (list, data, count) => {
    let newArr = list || [];
    let newCount = count || 0;
    const parent = data?.parent || data?.parent_id;
    if (parent) {
        newArr = newArr.map((el) => {
            let obj = el;
            if (obj.id === parent) {
                obj.comments = (obj.comments || []).filter(
                    (it) => it.id !== data.id,
                );
            }
            return obj;
        });
    } else {
        newCount--;
        newArr = newArr.filter((it) => it.id !== data.id);
    }
    return { list: newArr, count: newCount };
};

const commentsReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_COMMENTS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_COMMENTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                commentsList: state.commentsList.concat(payload.results || []),
                commentsCount: payload.count || 0,
                pageCount: payload.page_count || 1,
            };
        case GET_COMMENTS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка комментариев',
            };
        case GET_ADMIN_COMMENTS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ADMIN_COMMENTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                adminCommentsList: state.adminCommentsList.concat(
                    payload.results || [],
                ),
                adminCommentsCount: payload.count || 0,
                adminPageCount: payload.page_count || 1,
            };
        case GET_ADMIN_COMMENTS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения чата администратора',
            };
        case CREATE_COMMENT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_COMMENT_SUCCESS:
            const createdComment = { ...payload, localCreated: true };
            const createObj = addToComments(
                state.commentsList,
                createdComment,
                state.commentsCount,
            );
            return {
                ...state,
                commentsList: createObj.list,
                commentsCount: createObj.count,
                fetching: false,
                errorMessage: false,
            };
        case CREATE_COMMENT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка отправки ответа',
            };
        case UPDATE_COMMENT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_COMMENT_SUCCESS:
            const newComment = { ...payload };
            newComment.is_edit = true;
            const newList = updateInComments(state.commentsList, newComment);
            return {
                ...state,
                commentsList: newList,
                fetching: false,
                errorMessage: false,
            };
        case UPDATE_COMMENT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка изменения комментария',
            };
        case DELETE_COMMENT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case DELETE_COMMENT_SUCCESS:
            const itemDel = { ...payload };
            const deleteObj = removeFromComments(
                state.commentsList,
                itemDel,
                state.commentsCount,
            );
            return {
                ...state,
                commentsList: deleteObj.list,
                commentsCount: deleteObj.count,
                fetching: false,
                errorMessage: false,
            };
        case DELETE_COMMENT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления комментария',
            };
        case CREATE_ADMIN_COMMENT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_ADMIN_COMMENT_SUCCESS:
            const createdCommentA = { ...payload };
            const createObjA = addToComments(
                state.adminCommentsList,
                createdCommentA,
                state.adminCommentsCount,
            );
            return {
                ...state,
                adminCommentsList: createObjA.list,
                adminCommentsCount: createObjA.count,
                fetching: false,
                errorMessage: false,
            };
        case CREATE_ADMIN_COMMENT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка отправки ответа',
            };
        case UPDATE_ADMIN_COMMENT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_ADMIN_COMMENT_SUCCESS:
            const newCommentA = { ...payload };
            newCommentA.is_edit = true;
            const newListA = updateInComments(
                state.adminCommentsList,
                newCommentA,
            );
            return {
                ...state,
                adminCommentsList: newListA,
                fetching: false,
                errorMessage: false,
            };
        case UPDATE_ADMIN_COMMENT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка изменения комментария',
            };
        case DELETE_ADMIN_COMMENT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case DELETE_ADMIN_COMMENT_SUCCESS:
            const itemDelA = { ...payload };
            const deleteObjA = removeFromComments(
                state.adminCommentsList,
                itemDelA,
                state.adminCommentsCount,
            );
            return {
                ...state,
                adminCommentsList: deleteObjA.list,
                adminCommentsCount: deleteObjA.count,
                fetching: false,
                errorMessage: false,
            };
        case DELETE_ADMIN_COMMENT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления комментария',
            };
        case CLEAR_COMMENTS_DETAILS:
            return {
                ...state,
                ...initCommentsState,
            };
        case CLEAR_ADMIN_COMMENTS_DETAILS:
            return {
                ...state,
                ...initAdminCommentsState,
            };
        default:
            return state;
    }
};

export default commentsReducer;
