import {
    GET_USER_DETAILS_START,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    UPDATE_USER_START,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    CLEAR_USER_DETAILS,
} from '../actions/actionTypes';

const initialState = {
    userDetails: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const userEditReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_USER_DETAILS_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                userDetails: payload || {},
            };
        case GET_USER_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения пользователя',
            };
        case UPDATE_USER_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_USER_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка сохранения пользователя',
            };
        case CLEAR_USER_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default userEditReducer;
