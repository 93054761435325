import React, { forwardRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SnackbarProvider, useSnackbar, SnackbarContent } from 'notistack';
import { NOTIFICATIONS_DURATION } from '../../helpers/constants';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as CloseIcon } from '../../assets/icons/whiteCross.svg';
import {
    errorMessageUpdate,
    successMessageUpdate,
    warningMessageUpdate,
} from '../../actions/mainAction';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '403px',
            maxWidth: '403px',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '290px',
            maxWidth: '403px',
        },
        borderRadius: '5px',
        boxShadow: 'none',
        lineHeight: '16px',
        padding: '5px 20px',
        paddingRight: '8px',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        width: '100%',
    },
    error: {
        background: '#FA9C96',
    },
    success: {
        background: '#93BFA4',
    },
    warning: {
        background: '#FFCE7D',
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    text: {
        color: '#ffffff',
        fontSize: '14px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold',
        letterSpacing: '0.1px',
        textAlign: 'center',
    },
    expand: {
        float: 'right',
    },
}));

const ErrorAlert = forwardRef((props, ref) => {
    const { type, id, message } = props;
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <SnackbarContent
            ref={ref}
            className={`${classes.root} ${classes[type]}`}
        >
            <div className={classes.card}>
                <span className={classes.text}>{message}</span>
                <IconButton className={classes.expand} onClick={handleDismiss}>
                    <CloseIcon />
                </IconButton>
            </div>
        </SnackbarContent>
    );
});

const ErrorAlertWrapper = forwardRef((props, ref) => {
    const { id, message } = props;

    return <ErrorAlert ref={ref} id={id} message={message} type="error" />;
});

const SuccessAlertWrapper = forwardRef((props, ref) => {
    const { id, message } = props;

    return <ErrorAlert ref={ref} id={id} message={message} type="success" />;
});

const WarningAlertWrapper = forwardRef((props, ref) => {
    const { id, message } = props;

    return <ErrorAlert ref={ref} id={id} message={message} type="warning" />;
});

const AlertListener = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useSelector((state) => {
        const errorMessage = state.mainAdminReducer.errorMessage;
        if (!errorMessage) {
            return;
        }
        enqueueSnackbar(errorMessage, { variant: 'error' });
        dispatch(errorMessageUpdate(null));
    });

    useSelector((state) => {
        const successMessage = state.mainAdminReducer.successMessage;
        if (!successMessage) {
            return;
        }
        enqueueSnackbar(successMessage, { variant: 'success' });
        dispatch(successMessageUpdate(null));
    });

    useSelector((state) => {
        const warningMessage = state.mainAdminReducer.warningMessage;
        if (!warningMessage) {
            return;
        }
        enqueueSnackbar(warningMessage, { variant: 'warning' });
        dispatch(warningMessageUpdate(null));
    });

    return children;
};

const NotificationProvider = ({ children }) => {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            autoHideDuration={NOTIFICATIONS_DURATION}
            Components={{
                error: ErrorAlertWrapper,
                success: SuccessAlertWrapper,
                warning: WarningAlertWrapper,
            }}
        >
            <AlertListener>{children}</AlertListener>
        </SnackbarProvider>
    );
};

export default NotificationProvider;
