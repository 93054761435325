import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_MEDIA_START,
    GET_MEDIA_FAILED,
    GET_MEDIA_SUCCESS,
    GET_MEDIA_LIST_START,
    GET_MEDIA_LIST_SUCCESS,
    GET_MEDIA_LIST_FAILED,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetMediaList() {
    try {
        let url = `${API_DOMAIN}/articles/media/?page=1&page_size=3`;

        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_MEDIA_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: GET_MEDIA_FAILED,
            errorMessage: data,
        });
    } catch (error) {
        return yield put({
            type: GET_MEDIA_FAILED,
            errorMessage: error?.message,
        });
    }
}

function* handleGetMediaAttachmentsList(action) {
    try {
        const params = (action.payload || '').match(/(^|&)page=(\d+)(&|$)/im);
        const page = params[2];
        const url = `${API_DOMAIN}/articles/attachments/?${action.payload}`;

        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_MEDIA_LIST_SUCCESS,
                payload: {
                    ...data,
                    page,
                },
            });
        }
        return yield put({ type: GET_MEDIA_LIST_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_MEDIA_LIST_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_MEDIA_START, handleGetMediaList);
    yield takeLatest(GET_MEDIA_LIST_START, handleGetMediaAttachmentsList);
}
