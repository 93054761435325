import { combineReducers } from 'redux';
import mainAdminReducer from './mainReducer';
import newsEditReducer from './newsEditReducer';
import dictionaries from './dictionariesReducer';
import categoryEditReducer from './categoryEditReducer';
import lettersReducer from './lettersReducer';
import commentsReducer from './commentsReducer';
import lettersEditReducer from './lettersEditReducer';
import articlesReducer from './articlesReducer';
import articlesEditReducer from './articlesEditReducer';
import functionEditReducer from './functionEditReducer';
import profileReducer from './profileReducer';
import newsReducer from './newsReducer';
import agreementReducer from './agreementReducer';
import usersReducer from './usersReducer';
import accessRulesReducer from './accessRulesReducer';
import notificationReducer from './notificationReducer';
import userEditReducer from './userEditReducer';
import asuzEditReducer from './asuzEditReducer';
import questionsReducer from './questionsReducer';
import errorsReducer from './errorsReducer';
import mediaReducer from './mediaReducer';
import draftReducer from './draftReducer';
import scheduleEditReducer from './scheduleEditReducer';
import myArticlesReducer from './myArticlesReducer';
import unfinishedReducer from './unfinishedReducer';
import requestReducer from './requestReducer';

export default combineReducers({
    mainAdminReducer,
    newsEditReducer,
    dictionaries,
    categoryEditReducer,
    lettersReducer,
    commentsReducer,
    lettersEditReducer,
    articlesReducer,
    articlesEditReducer,
    functionEditReducer,
    profileReducer,
    newsReducer,
    agreementReducer,
    usersReducer,
    accessRulesReducer,
    notificationReducer,
    userEditReducer,
    asuzEditReducer,
    questionsReducer,
    errorsReducer,
    mediaReducer,
    draftReducer,
    scheduleEditReducer,
    myArticlesReducer,
    unfinishedReducer,
    requestReducer,
});
