import {
    GET_ERROR_DATA_START,
    UPDATE_ERRORS_PAGE,
    GET_ERROR_NOTIFIES_START,
    CLEAR_ERROR_DATA,
} from './actionTypes';

export const getErrorData = (payload) => ({
    type: GET_ERROR_DATA_START,
    payload,
});

export const updateErrorsPage = (payload) => ({
    type: UPDATE_ERRORS_PAGE,
    payload,
});

export const getErrorsNotifies = () => ({
    type: GET_ERROR_NOTIFIES_START,
});

export const clearErrorData = () => ({ type: CLEAR_ERROR_DATA });
