import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_USER_NOTIFICATIONS_START,
    GET_USER_NOTIFICATIONS_SUCCESS,
    GET_USER_NOTIFICATIONS_FAILED,
    SET_USER_NOTIFICATIONS_READ_START,
    SET_USER_NOTIFICATIONS_READ_SUCCESS,
    SET_USER_NOTIFICATIONS_READ_FAILED,
    SET_USER_NOTIFICATIONS_READ_ALL_START,
    SET_USER_NOTIFICATIONS_READ_ALL_SUCCESS,
    SET_USER_NOTIFICATIONS_READ_ALL_FAILED,
} from '../actions/actionTypes';

function* handleGetUserNotifications() {
    try {
        const url = `${API_DOMAIN}/notifications/?is_read=false&page_size=10000&ordering=-create_dt`;
        const data = yield call(request.get, url);
        if (data && data.results) {
            return yield put({
                type: GET_USER_NOTIFICATIONS_SUCCESS,
                payload: data.results,
            });
        }
        return yield put({ type: GET_USER_NOTIFICATIONS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_USER_NOTIFICATIONS_FAILED, error });
    }
}

function* handleSetUserNotificationsRead(action) {
    try {
        const url = `${API_DOMAIN}/notifications/set_read/`;
        const data = yield call(request.post, url, { ids: action.payload });
        if (data) {
            return yield put({
                type: SET_USER_NOTIFICATIONS_READ_SUCCESS,
                payload: action.payload,
            });
        }
        return yield put({
            type: SET_USER_NOTIFICATIONS_READ_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: SET_USER_NOTIFICATIONS_READ_FAILED, error });
    }
}

function* handleSetUserNotificationsReadAll(action) {
    try {
        const url = `${API_DOMAIN}/notifications/set_read/all/`;
        const data = yield call(request.post, url, { type: action.payload });
        if (data) {
            yield put({
                type: SET_USER_NOTIFICATIONS_READ_ALL_SUCCESS,
                payload: action.payload,
            });
            return yield put({
                type: GET_USER_NOTIFICATIONS_START,
                payload: action.payload,
            });
        }
        return yield put({
            type: SET_USER_NOTIFICATIONS_READ_ALL_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({
            type: SET_USER_NOTIFICATIONS_READ_ALL_FAILED,
            error,
        });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_USER_NOTIFICATIONS_START, handleGetUserNotifications);
    yield takeLatest(
        SET_USER_NOTIFICATIONS_READ_START,
        handleSetUserNotificationsRead,
    );
    yield takeLatest(
        SET_USER_NOTIFICATIONS_READ_ALL_START,
        handleSetUserNotificationsReadAll,
    );
}
