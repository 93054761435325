import {
    GET_ASUZ_DETAILS_START,
    GET_ASUZ_DETAILS_SUCCESS,
    GET_ASUZ_DETAILS_FAILED,
    CREATE_ASUZ_TASK_START,
    CREATE_ASUZ_TASK_SUCCESS,
    CREATE_ASUZ_TASK_FAILED,
    UPDATE_ASUZ_TASK_START,
    UPDATE_ASUZ_TASK_SUCCESS,
    UPDATE_ASUZ_TASK_FAILED,
    DELETE_ASUZ_TASK_START,
    DELETE_ASUZ_TASK_SUCCESS,
    DELETE_ASUZ_TASK_FAILED,
    REJECT_ASUZ_TASK_START,
    REJECT_ASUZ_TASK_SUCCESS,
    REJECT_ASUZ_TASK_FAILED,
    UPDATE_ASUZ_TASK_STATUS_SUCCESS,
    UPDATE_ASUZ_TASK_STATUS_START,
    UPDATE_ASUZ_TASK_STATUS_FAILED,
    CLEAR_ASUZ_DETAILS,
} from '../actions/actionTypes';

const initialState = {
    asuzDetails: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const asuzEditReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_ASUZ_DETAILS_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_ASUZ_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                asuzDetails: payload || {},
            };
        case GET_ASUZ_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения задачи АСУЗ',
            };
        case CREATE_ASUZ_TASK_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_ASUZ_TASK_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case CREATE_ASUZ_TASK_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания задачи АСУЗ',
            };
        case UPDATE_ASUZ_TASK_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_ASUZ_TASK_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_ASUZ_TASK_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка сохранения задачи АСУЗ',
            };
        case DELETE_ASUZ_TASK_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case DELETE_ASUZ_TASK_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case DELETE_ASUZ_TASK_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления задачи АСУЗ',
            };
        case REJECT_ASUZ_TASK_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case REJECT_ASUZ_TASK_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case REJECT_ASUZ_TASK_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка отмены задачи АСУЗ',
            };
        case UPDATE_ASUZ_TASK_STATUS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_ASUZ_TASK_STATUS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case UPDATE_ASUZ_TASK_STATUS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка обновления статуса задачи АСУЗ',
            };
        case CLEAR_ASUZ_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default asuzEditReducer;
