import { call, put, takeEvery } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_LETTERS_DETAILS_START,
    GET_LETTERS_DETAILS_SUCCESS,
    GET_LETTERS_DETAILS_FAILED,
    CREATE_LETTERS_START,
    CREATE_LETTERS_SUCCESS,
    CREATE_LETTERS_FAILED,
    UPDATE_LETTERS_START,
    UPDATE_LETTERS_SUCCESS,
    UPDATE_LETTERS_FAILED,
    UPDATE_LETTERS_POSM_DATES_START,
    UPDATE_LETTERS_POSM_DATES_SUCCESS,
    UPDATE_LETTERS_POSM_DATES_FAILED,
} from '../actions/actionTypes';

function* handleGetLettersEdit(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_LETTERS_DETAILS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_LETTERS_DETAILS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_LETTERS_DETAILS_FAILED, error });
    }
}

function* handleCreateLetter(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({ type: CREATE_LETTERS_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_LETTERS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_LETTERS_FAILED, error });
    }
}

function* handleUpdateLetter(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload.lId}/`;
        const data = yield call(request.put, url, action.payload);
        if (data) {
            return yield put({ type: UPDATE_LETTERS_SUCCESS, payload: data });
        }
        return yield put({ type: UPDATE_LETTERS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_LETTERS_FAILED, error });
    }
}

function* handleUpdateLetterPosmDates(action) {
    try {
        const { id, obj, cb } = action?.payload || {};
        const url = `${API_DOMAIN}/info_messages/${id}/pos_stock/`;
        const data = yield call(request.patch, url, obj);
        if (data) {
            cb && cb();
            return yield put({
                type: UPDATE_LETTERS_POSM_DATES_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: UPDATE_LETTERS_POSM_DATES_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: UPDATE_LETTERS_POSM_DATES_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_LETTERS_DETAILS_START, handleGetLettersEdit);
    yield takeEvery(CREATE_LETTERS_START, handleCreateLetter);
    yield takeEvery(UPDATE_LETTERS_START, handleUpdateLetter);
    yield takeEvery(
        UPDATE_LETTERS_POSM_DATES_START,
        handleUpdateLetterPosmDates,
    );
}
