import {
    UPDATE_USERS_PAGE,
    GET_USERS_START,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
} from '../actions/actionTypes';

const initialState = {
    currentPage: 1,
};

const usersReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_USERS_PAGE:
            return {
                ...state,
                currentPage: payload || {},
            };
        case GET_USERS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload.results,
            };
        case GET_USERS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка пользователей',
            };
        default:
            return state;
    }
};

export default usersReducer;
