import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import Header from '../../components/CommonParts/Header';
import { API_DOMAIN } from '../../helpers/constants';
import { checkIfTest } from '../../helpers/m';
import { DEFAULT_ROUTE } from '../../helpers/routes';
import notify from '../../helpers/notify';
import {
    getFromStorage,
    removeFromStorage,
    setToStorage,
} from '../../helpers/storage';
import { request } from '../../helpers/api';
// import { AppContext } from '../../helpers/context';
import OutlinedInput from '../../components/CommonParts/OutlinedInput';
import ButtonDefault from '../../components/CommonParts/ButtonDefault';
import styles from './signIn.module.scss';

export const SignInComponent = () => {
    // const { keycloak } = useContext(AppContext);
    const { login } = useContext(AuthContext);
    const [localItem, setLocalItem] = useState({});
    const [withError, setWithError] = useState({
        login: false,
        password: false,
    });
    const [fetching, setFetching] = useState(false);
    const [errorMessage] = useState(null);
    const disableLoginBtn = Object.values(withError).includes(true) || fetching;

    useEffect(() => {
        const err = getFromStorage('appShowError');
        removeFromStorage('appShowError');
        if (err) {
            notify.error(err);
        }
    }, []);

    const localAuth = (e) => {
        e && e.preventDefault && e.preventDefault();
        login();
    };

    const notValidLogin = () => {
        const { login, password } = localItem;
        const errors = {
            login: !(login && login.trim() && login.trim().length > 0),
            password: !(password && password.length > 0),
        };
        let hasErrors = Object.values(errors).includes(true);
        if (hasErrors) {
            hasErrors = true;
            setWithError(errors);
        }
        return hasErrors;
    };

    const onChangeField = (value, type) => {
        setLocalItem({
            ...localItem,
            [type]: value,
        });
        setWithError({
            ...withError,
            [type]: false,
        });
    };

    const onClickLogin = () => {
        if (notValidLogin()) return;
        setFetching(true);
        request
            .post(`${API_DOMAIN}/api-token-auth/`, {
                username: localItem.login,
                password: localItem.password,
            })
            .then((res) => {
                setFetching(false);
                if (!res || !res.token) return;
                setToStorage('user', {
                    token: res.token,
                    refresh: 'test',
                });
                return window.location.replace(DEFAULT_ROUTE);
            })
            .catch(() => setFetching(false));
    };

    return (
        <div className={styles.authBlock}>
            <Header simple />
            <div className={styles.signInContainer}>
                {checkIfTest() ? (
                    <div className={styles.authForm}>
                        <h5 className={styles.formTitle}>Войдите в аккаунт</h5>
                        <div className={styles.mb20}>
                            <OutlinedInput
                                label="Логин"
                                type="login"
                                value={localItem?.login}
                                InputLabelProps={{ shrink: true }}
                                onChange={onChangeField}
                                error={withError.login}
                            />
                        </div>
                        <OutlinedInput
                            label="Пароль"
                            type="password"
                            value={localItem?.password}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChangeField}
                            error={withError.password}
                        />
                        <div className={styles.btnWrapper}>
                            <ButtonDefault
                                bigButton={true}
                                disabled={disableLoginBtn}
                                label="Войти"
                                onClick={onClickLogin}
                            />
                        </div>
                    </div>
                ) : (
                    <form>
                        <input
                            type="submit"
                            value="Нажмите для авторизации"
                            className={styles.signInBtn}
                            disabled={fetching}
                            onClick={localAuth}
                        />
                        {errorMessage && <div>{errorMessage}</div>}
                    </form>
                )}
            </div>
        </div>
    );
};

export default SignInComponent;
