import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_ARTICLES_DATES_START,
    GET_ARTICLES_DATES_SUCCESS,
    GET_ARTICLES_DATES_FAILED,
    GET_ARTICLE_DETAILS_START,
    GET_ARTICLE_DETAILS_SUCCESS,
    GET_ARTICLE_DETAILS_FAILED,
    GET_ARTICLES_LIST_BY_DATE_START,
    GET_ARTICLES_LIST_BY_DATE_SUCCESS,
    GET_ARTICLES_LIST_BY_DATE_FAILED,
    SET_ARTICLE_FAVOURITE_START,
    SET_ARTICLE_FAVOURITE_SUCCESS,
    SET_ARTICLE_FAVOURITE_FAILED,
    REMOVE_ARTICLE_FAVOURITE_START,
    REMOVE_ARTICLE_FAVOURITE_SUCCESS,
    REMOVE_ARTICLE_FAVOURITE_FAILED,
    SET_ARTICLE_LIKE_START,
    SET_ARTICLE_LIKE_SUCCESS,
    SET_ARTICLE_LIKE_FAILED,
    REMOVE_ARTICLE_LIKE_START,
    REMOVE_ARTICLE_LIKE_SUCCESS,
    REMOVE_ARTICLE_LIKE_FAILED,
    SET_ARTICLE_DISLIKE_START,
    SET_ARTICLE_DISLIKE_SUCCESS,
    SET_ARTICLE_DISLIKE_FAILED,
    REMOVE_ARTICLE_DISLIKE_START,
    REMOVE_ARTICLE_DISLIKE_SUCCESS,
    REMOVE_ARTICLE_DISLIKE_FAILED,
    MASS_REMOVE_ARTICLES_START,
    MASS_REMOVE_ARTICLES_SUCCESS,
    MASS_REMOVE_ARTICLES_FAILED,
    CHANGE_ARTICLES_START,
    CHANGE_ARTICLES_SUCCESS,
    CHANGE_ARTICLES_FAILED,
    GET_ARTICLE_DELETED_DETAILS_START,
    GET_ARTICLE_DELETED_DETAILS_SUCCESS,
    GET_ARTICLE_DELETED_DETAILS_FAILED,
    RECOVER_DELETED_ARTICLE_START,
    RECOVER_DELETED_ARTICLE_SUCCESS,
    RECOVER_DELETED_ARTICLE_FAILED,
    ERASE_DELETED_ARTICLE_START,
    ERASE_DELETED_ARTICLE_SUCCESS,
    ERASE_DELETED_ARTICLE_FAILED,
    GET_ARTICLES_LIST_START,
    GET_ARTICLES_LIST_SUCCESS,
    GET_ARTICLES_LIST_FAILED,
} from '../actions/actionTypes';
import notify from '../helpers/notify';

function* handleGetArticlesDates(action) {
    try {
        const { msgType } = action.payload;
        const url = `${API_DOMAIN}/articles/date_list/?msg_type=${msgType}&page_size=10000&ordering=-plan_public_date__date`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ARTICLES_DATES_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_ARTICLES_DATES_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_ARTICLES_DATES_FAILED, error });
    }
}

function* handleGetArticleDetails(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ARTICLE_DETAILS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_ARTICLE_DETAILS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_ARTICLE_DETAILS_FAILED, error });
    }
}

function* handleGetArticleDeletedDetails(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/?is_delete=true`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ARTICLE_DELETED_DETAILS_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: GET_ARTICLE_DELETED_DETAILS_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: GET_ARTICLE_DELETED_DETAILS_FAILED, error });
    }
}

function* handleGetArticlesListByDate(action) {
    try {
        const { id, msgType, planPublicDate } = action.payload;
        const url = `${API_DOMAIN}/articles/titles/?msg_type=${msgType}&plan_public_date=${planPublicDate}&page_size=10000`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ARTICLES_LIST_BY_DATE_SUCCESS,
                payload: { data, id },
            });
        }
        return yield put({
            type: GET_ARTICLES_LIST_BY_DATE_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: GET_ARTICLES_LIST_BY_DATE_FAILED, error });
    }
}

function* handleSetArticleFavourite(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/favourite/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: SET_ARTICLE_FAVOURITE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: SET_ARTICLE_FAVOURITE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: SET_ARTICLE_FAVOURITE_FAILED, error });
    }
}

function* handleRemoveArticleFavourite(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/remove_favourite/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: REMOVE_ARTICLE_FAVOURITE_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: REMOVE_ARTICLE_FAVOURITE_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: REMOVE_ARTICLE_FAVOURITE_FAILED, error });
    }
}

function* handleSetArticleLike(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/like/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: SET_ARTICLE_LIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: SET_ARTICLE_LIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: SET_ARTICLE_LIKE_FAILED, error });
    }
}

function* handleRemoveArticleLike(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/remove_like/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: REMOVE_ARTICLE_LIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: REMOVE_ARTICLE_LIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: REMOVE_ARTICLE_LIKE_FAILED, error });
    }
}

function* handleSetArticleDislike(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/dislike/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: SET_ARTICLE_DISLIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: SET_ARTICLE_DISLIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: SET_ARTICLE_DISLIKE_FAILED, error });
    }
}

function* handleRemoveArticleDislike(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/remove_dislike/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: REMOVE_ARTICLE_DISLIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: REMOVE_ARTICLE_DISLIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: REMOVE_ARTICLE_DISLIKE_FAILED, error });
    }
}

function* handleMassRemoveArticles(action) {
    try {
        const url = `${API_DOMAIN}/articles/${action.payload}/`;
        const data = yield call(request.post, url, action.payload.data);
        if (data) {
            action.payload && action.payload.cb && action.payload.cb();
            return yield put({
                type: MASS_REMOVE_ARTICLES_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: MASS_REMOVE_ARTICLES_FAILED, error: data });
    } catch (error) {
        return yield put({ type: MASS_REMOVE_ARTICLES_FAILED, error });
    }
}

function* handleChangeArticleStatus(action) {
    try {
        const { url: lUrl, cb, notifyText, data: payloadData } = action.payload;
        const url = `${API_DOMAIN}/articles/${lUrl}/`;
        const data = yield call(request.post, url, payloadData || {});
        if (data) {
            cb && cb();
            notifyText && notify.success(notifyText);
            return yield put({
                type: CHANGE_ARTICLES_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: CHANGE_ARTICLES_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CHANGE_ARTICLES_FAILED, error });
    }
}

function* handleRecoverArticle(action) {
    try {
        const { id, cb } = action.payload;
        const url = `${API_DOMAIN}/articles/${id}/recovery/`;
        const data = yield call(request.put, url);
        if (data) {
            cb && cb();
            return yield put({
                type: RECOVER_DELETED_ARTICLE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: RECOVER_DELETED_ARTICLE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: RECOVER_DELETED_ARTICLE_FAILED, error });
    }
}

function* handleEraseArticle(action) {
    try {
        const { id, cb } = action.payload;
        const url = `${API_DOMAIN}/articles/${id}/delete`;
        const data = yield call(request.delete, url);
        if (data) {
            cb && cb();
            return yield put({
                type: ERASE_DELETED_ARTICLE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: ERASE_DELETED_ARTICLE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: ERASE_DELETED_ARTICLE_FAILED, error });
    }
}

function* handleGetArticlesList(action) {
    try {
        const params = (action.payload || '').match(/(^|&)page=(\d+)(&|$)/im);
        const page = params[2];
        const url = `${API_DOMAIN}/articles/?${action.payload}`;

        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ARTICLES_LIST_SUCCESS,
                payload: {
                    ...data,
                    page,
                },
            });
        }
        return yield put({ type: GET_ARTICLES_LIST_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_ARTICLES_LIST_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_ARTICLES_DATES_START, handleGetArticlesDates);
    yield takeLatest(GET_ARTICLE_DETAILS_START, handleGetArticleDetails);
    yield takeLatest(
        GET_ARTICLE_DELETED_DETAILS_START,
        handleGetArticleDeletedDetails,
    );
    yield takeLatest(
        GET_ARTICLES_LIST_BY_DATE_START,
        handleGetArticlesListByDate,
    );
    yield takeLatest(SET_ARTICLE_FAVOURITE_START, handleSetArticleFavourite);
    yield takeLatest(
        REMOVE_ARTICLE_FAVOURITE_START,
        handleRemoveArticleFavourite,
    );
    yield takeLatest(SET_ARTICLE_LIKE_START, handleSetArticleLike);
    yield takeLatest(REMOVE_ARTICLE_LIKE_START, handleRemoveArticleLike);
    yield takeLatest(SET_ARTICLE_DISLIKE_START, handleSetArticleDislike);
    yield takeLatest(REMOVE_ARTICLE_DISLIKE_START, handleRemoveArticleDislike);
    yield takeLatest(MASS_REMOVE_ARTICLES_START, handleMassRemoveArticles);
    yield takeLatest(CHANGE_ARTICLES_START, handleChangeArticleStatus);
    yield takeLatest(RECOVER_DELETED_ARTICLE_START, handleRecoverArticle);
    yield takeLatest(ERASE_DELETED_ARTICLE_START, handleEraseArticle);
    yield takeLatest(GET_ARTICLES_LIST_START, handleGetArticlesList);
}
