import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_LETTERS_DATES_START,
    GET_LETTERS_DATES_SUCCESS,
    GET_LETTERS_DATES_FAILED,
    GET_LETTERS_UNPUBLISHED_START,
    GET_LETTERS_UNPUBLISHED_SUCCESS,
    GET_LETTERS_UNPUBLISHED_FAILED,
    GET_LETTER_DETAILS_START,
    GET_LETTER_DETAILS_SUCCESS,
    GET_LETTER_DETAILS_FAILED,
    GET_LETTERS_LIST_BY_DATE_START,
    GET_LETTERS_LIST_BY_DATE_SUCCESS,
    GET_LETTERS_LIST_BY_DATE_FAILED,
    SET_LETTER_FAVOURITE_START,
    SET_LETTER_FAVOURITE_SUCCESS,
    SET_LETTER_FAVOURITE_FAILED,
    REMOVE_LETTER_FAVOURITE_START,
    REMOVE_LETTER_FAVOURITE_SUCCESS,
    REMOVE_LETTER_FAVOURITE_FAILED,
    SET_LETTER_LIKE_START,
    SET_LETTER_LIKE_SUCCESS,
    SET_LETTER_LIKE_FAILED,
    REMOVE_LETTER_LIKE_START,
    REMOVE_LETTER_LIKE_SUCCESS,
    REMOVE_LETTER_LIKE_FAILED,
    SET_LETTER_DISLIKE_START,
    SET_LETTER_DISLIKE_SUCCESS,
    SET_LETTER_DISLIKE_FAILED,
    REMOVE_LETTER_DISLIKE_START,
    REMOVE_LETTER_DISLIKE_SUCCESS,
    REMOVE_LETTER_DISLIKE_FAILED,
    COPY_ONE_LETTER_START,
    COPY_ONE_LETTER_SUCCESS,
    COPY_ONE_LETTER_FAILED,
    ADD_TO_CHAIN_LETTER_START,
    ADD_TO_CHAIN_LETTER_SUCCESS,
    ADD_TO_CHAIN_LETTER_FAILED,
    MASS_REMOVE_LETTERS_START,
    MASS_REMOVE_LETTERS_SUCCESS,
    MASS_REMOVE_LETTERS_FAILED,
    GET_LETTERS_CHAIN_LIST_START,
    GET_LETTERS_CHAIN_LIST_SUCCESS,
    GET_LETTERS_CHAIN_LIST_FAILED,
    CHANGE_LETTER_START,
    CHANGE_LETTER_SUCCESS,
    CHANGE_LETTER_FAILED,
    GET_LETTERS_LIST_START,
    GET_LETTERS_LIST_SUCCESS,
    GET_LETTERS_LIST_FAILED,
} from '../actions/actionTypes';
import notify from '../helpers/notify';

function* handleGetLettersDates(action) {
    try {
        const { msgType } = action.payload;
        const url = `${API_DOMAIN}/info_messages/date_list/?msg_type=${msgType}&page_size=10000&ordering=-plan_public_date__date`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_LETTERS_DATES_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_LETTERS_DATES_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_LETTERS_DATES_FAILED, error });
    }
}

function* handleGetLettersUnpublished() {
    try {
        const url = `${API_DOMAIN}/info_messages/?is_publish_immediately=true&plan_public_date_isnull=true&page_size=1000`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_LETTERS_UNPUBLISHED_SUCCESS,
                payload: data.count,
            });
        }
        return yield put({ type: GET_LETTERS_UNPUBLISHED_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_LETTERS_UNPUBLISHED_FAILED, error });
    }
}

function* handleGetLetterDetails(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_LETTER_DETAILS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_LETTER_DETAILS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_LETTER_DETAILS_FAILED, error });
    }
}

function* handleGetLettersListByDate(action) {
    try {
        const { id, msgType, planPublicDate } = action.payload;
        const url = `${API_DOMAIN}/info_messages/titles/?msg_type=${msgType}&plan_public_date=${planPublicDate}&page_size=10000`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_LETTERS_LIST_BY_DATE_SUCCESS,
                payload: { data, id },
            });
        }
        return yield put({
            type: GET_LETTERS_LIST_BY_DATE_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({ type: GET_LETTERS_LIST_BY_DATE_FAILED, error });
    }
}

function* handleSetLetterFavourite(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/favourite/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: SET_LETTER_FAVOURITE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: SET_LETTER_FAVOURITE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: SET_LETTER_FAVOURITE_FAILED, error });
    }
}

function* handleRemoveLetterFavourite(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/remove_favourite/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: REMOVE_LETTER_FAVOURITE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: REMOVE_LETTER_FAVOURITE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: REMOVE_LETTER_FAVOURITE_FAILED, error });
    }
}

function* handleSetLetterLike(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/like/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: SET_LETTER_LIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: SET_LETTER_LIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: SET_LETTER_LIKE_FAILED, error });
    }
}

function* handleRemoveLetterLike(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/remove_like/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: REMOVE_LETTER_LIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: REMOVE_LETTER_LIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: REMOVE_LETTER_LIKE_FAILED, error });
    }
}

function* handleSetLetterDislike(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/dislike/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: SET_LETTER_DISLIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: SET_LETTER_DISLIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: SET_LETTER_DISLIKE_FAILED, error });
    }
}

function* handleRemoveLetterDislike(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/remove_dislike/`;
        const data = yield call(request.post, url);
        if (data) {
            return yield put({
                type: REMOVE_LETTER_DISLIKE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: REMOVE_LETTER_DISLIKE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: REMOVE_LETTER_DISLIKE_FAILED, error });
    }
}

function* handleCopyOneLetter(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload.id}/copy/`;
        const data = yield call(request.post, url, {
            is_copy_attachments: true,
        });
        if (data) {
            action.payload && action.payload.cb && action.payload.cb(data.id);
            return yield put({
                type: COPY_ONE_LETTER_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: COPY_ONE_LETTER_FAILED, error: data });
    } catch (error) {
        return yield put({ type: COPY_ONE_LETTER_FAILED, error });
    }
}

function* handleAddToChainLetter(action) {
    try {
        const { id, type, copyAttaches, cb } = action.payload;
        const url = `${API_DOMAIN}/info_messages/${id}/copy/`;
        const body = {
            chain_type: type,
            is_copy_attachments: copyAttaches,
        };
        const data = yield call(request.post, url, body);
        if (data) {
            cb && cb(data.id);
            return yield put({
                type: ADD_TO_CHAIN_LETTER_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: ADD_TO_CHAIN_LETTER_FAILED, error: data });
    } catch (error) {
        return yield put({ type: ADD_TO_CHAIN_LETTER_FAILED, error });
    }
}

function* handleMassRemoveLetters(action) {
    try {
        const url = `${API_DOMAIN}/info_messages/${action.payload}/`;
        const data = yield call(request.post, url, action.payload.data);
        if (data) {
            action.payload && action.payload.cb && action.payload.cb();
            return yield put({
                type: MASS_REMOVE_LETTERS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: MASS_REMOVE_LETTERS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: MASS_REMOVE_LETTERS_FAILED, error });
    }
}

function* handleGetLettersChainList(action) {
    try {
        const id = action.payload;
        if (!id) {
            return yield put({
                type: GET_LETTERS_CHAIN_LIST_FAILED,
                error: '',
            });
        }
        const url = `${API_DOMAIN}/info_messages/${id}/chain/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_LETTERS_CHAIN_LIST_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_LETTERS_CHAIN_LIST_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_LETTERS_CHAIN_LIST_FAILED, error });
    }
}

function* handleChangeLetterStatus(action) {
    try {
        const { url: lUrl, cb, notifyText, data: payloadData } = action.payload;
        const url = `${API_DOMAIN}/info_messages/${lUrl}/`;
        const data = yield call(request.post, url, payloadData || {});
        if (data) {
            cb && cb();
            notifyText && notify.success(notifyText);
            return yield put({
                type: CHANGE_LETTER_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: CHANGE_LETTER_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CHANGE_LETTER_FAILED, error });
    }
}

function* handleGetLettersList(action) {
    try {
        const params = (action.payload || '').match(/(^|&)page=(\d+)(&|$)/im);
        const page = params[2];
        const url = `${API_DOMAIN}/info_messages/?${action.payload}`;

        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_LETTERS_LIST_SUCCESS,
                payload: {
                    ...data,
                    page,
                },
            });
        }
        return yield put({ type: GET_LETTERS_LIST_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_LETTERS_LIST_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_LETTERS_DATES_START, handleGetLettersDates);
    yield takeLatest(
        GET_LETTERS_UNPUBLISHED_START,
        handleGetLettersUnpublished,
    );
    yield takeLatest(GET_LETTER_DETAILS_START, handleGetLetterDetails);
    yield takeLatest(
        GET_LETTERS_LIST_BY_DATE_START,
        handleGetLettersListByDate,
    );
    yield takeLatest(SET_LETTER_FAVOURITE_START, handleSetLetterFavourite);
    yield takeLatest(
        REMOVE_LETTER_FAVOURITE_START,
        handleRemoveLetterFavourite,
    );
    yield takeLatest(SET_LETTER_LIKE_START, handleSetLetterLike);
    yield takeLatest(REMOVE_LETTER_LIKE_START, handleRemoveLetterLike);
    yield takeLatest(SET_LETTER_DISLIKE_START, handleSetLetterDislike);
    yield takeLatest(REMOVE_LETTER_DISLIKE_START, handleRemoveLetterDislike);
    yield takeLatest(COPY_ONE_LETTER_START, handleCopyOneLetter);
    yield takeLatest(ADD_TO_CHAIN_LETTER_START, handleAddToChainLetter);
    yield takeLatest(MASS_REMOVE_LETTERS_START, handleMassRemoveLetters);
    yield takeLatest(GET_LETTERS_CHAIN_LIST_START, handleGetLettersChainList);
    yield takeLatest(CHANGE_LETTER_START, handleChangeLetterStatus);
    yield takeLatest(GET_LETTERS_LIST_START, handleGetLettersList);
}
