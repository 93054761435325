import { call, put, takeEvery } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    GET_MY_ARTICLES_COUNT_START,
    GET_MY_ARTICLES_COUNT_SUCCESS,
    GET_MY_ARTICLES_COUNT_FAILED,
} from '../actions/actionTypes';

export function* handleGetMyArticlesCount() {
    try {
        const url = `${API_DOMAIN}/users/my_info_articles/counts/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_MY_ARTICLES_COUNT_SUCCESS,
                payload: data,
            });
        }
        return yield put({
            type: GET_MY_ARTICLES_COUNT_FAILED,
            payload: data,
        });
    } catch (error) {
        return yield put({
            type: GET_MY_ARTICLES_COUNT_FAILED,
            payload: error?.message,
        });
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_MY_ARTICLES_COUNT_START, handleGetMyArticlesCount);
}
