import {
    UPDATE_ERRORS_PAGE,
    GET_ERRORS_START,
    GET_ERRORS_SUCCESS,
    GET_ERRORS_FAILED,
    GET_ERROR_DATA_FAILED,
    GET_ERROR_DATA_SUCCESS,
    GET_ERROR_DATA_START,
    GET_ERROR_NOTIFIES_START,
    GET_ERROR_NOTIFIES_SUCCESS,
    GET_ERROR_NOTIFIES_FAILED,
    CLEAR_ERROR_DATA,
} from '../actions/actionTypes';

const initialState = {
    currentPage: 1,
    errorData: null,
    errorsCount: 0,
};

const errorsReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_ERRORS_PAGE:
            return {
                ...state,
                currentPage: payload || {},
            };
        case GET_ERRORS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ERRORS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload.results,
            };
        case GET_ERRORS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка ошибок',
            };
        case GET_ERROR_DATA_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ERROR_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                errorData: payload,
            };
        case GET_ERROR_DATA_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения ошибки',
            };
        case GET_ERROR_NOTIFIES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ERROR_NOTIFIES_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                errorsCount: payload,
            };
        case GET_ERROR_NOTIFIES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения количества',
            };
        case CLEAR_ERROR_DATA:
            return {
                ...state,
                errorData: null,
                errorMessage: false,
            };
        default:
            return state;
    }
};

export default errorsReducer;
