import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_QUESTIONS_START,
    GET_QUESTIONS_FAILED,
    GET_QUESTIONS_SUCCESS,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetQuestionsList(action) {
    try {
        const { id, extra } = action.payload;
        let artStr = id ? 'article_id=' + id : '';
        let url = `${API_DOMAIN}/articles/test_questions/?${artStr}&${
            extra || ''
        }`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_QUESTIONS_SUCCESS,
                payload: data,
            });
        }
        yield put({
            type: GET_QUESTIONS_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({
            type: GET_QUESTIONS_FAILED,
            errorMessage: error?.message,
        });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_QUESTIONS_START, handleGetQuestionsList);
}
