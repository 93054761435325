import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import {
    CREATE_FUNCTION_START,
    CREATE_FUNCTION_FAILED,
    CREATE_FUNCTION_SUCCESS,
    UPDATE_FUNCTION_START,
    UPDATE_FUNCTION_SUCCESS,
    UPDATE_FUNCTION_FAILED,
} from '../actions/actionTypes';

function* handleCreateNews(action) {
    try {
        const url = `${API_DOMAIN}/dictionaries/functions/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({ type: CREATE_FUNCTION_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_FUNCTION_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_FUNCTION_FAILED, error });
    }
}

function* handleUpdateNews(action) {
    try {
        const url = `${API_DOMAIN}/dictionaries/functions/${action.payload.id}/`;
        const data = yield call(request.put, url, action.payload);
        if (data) {
            return yield put({ type: UPDATE_FUNCTION_SUCCESS, payload: data });
        }
        return yield put({ type: UPDATE_FUNCTION_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_FUNCTION_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(CREATE_FUNCTION_START, handleCreateNews);
    yield takeLatest(UPDATE_FUNCTION_START, handleUpdateNews);
}
