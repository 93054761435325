import {
    GET_ARTICLE_DETAILS_START,
    GET_ARTICLE_DETAILS_SUCCESS,
    GET_ARTICLE_DETAILS_FAILED,
    SET_ARTICLE_FAVOURITE_START,
    SET_ARTICLE_FAVOURITE_SUCCESS,
    SET_ARTICLE_FAVOURITE_FAILED,
    REMOVE_ARTICLE_FAVOURITE_START,
    REMOVE_ARTICLE_FAVOURITE_SUCCESS,
    REMOVE_ARTICLE_FAVOURITE_FAILED,
    CLEAR_ARTICLE_DETAILS,
    SET_ARTICLE_LIKE_START,
    SET_ARTICLE_LIKE_SUCCESS,
    SET_ARTICLE_LIKE_FAILED,
    REMOVE_ARTICLE_LIKE_START,
    REMOVE_ARTICLE_LIKE_SUCCESS,
    REMOVE_ARTICLE_LIKE_FAILED,
    SET_ARTICLE_DISLIKE_START,
    SET_ARTICLE_DISLIKE_SUCCESS,
    SET_ARTICLE_DISLIKE_FAILED,
    REMOVE_ARTICLE_DISLIKE_START,
    REMOVE_ARTICLE_DISLIKE_SUCCESS,
    REMOVE_ARTICLE_DISLIKE_FAILED,
    REMOVE_ITEM_FROM_ARTICLE_DATA_BY_ID,
    GET_ARTICLES_DATES_START,
    GET_ARTICLES_DATES_SUCCESS,
    GET_ARTICLES_DATES_FAILED,
    GET_ARTICLES_LIST_BY_DATE_START,
    GET_ARTICLES_LIST_BY_DATE_SUCCESS,
    GET_ARTICLES_LIST_BY_DATE_FAILED,
    MASS_REMOVE_ARTICLES_START,
    MASS_REMOVE_ARTICLES_SUCCESS,
    MASS_REMOVE_ARTICLES_FAILED,
    CHANGE_ARTICLES_START,
    CHANGE_ARTICLES_SUCCESS,
    CHANGE_ARTICLES_FAILED,
    UPDATE_ARTICLE_STATUS,
    GET_ARTICLE_DELETED_DETAILS_START,
    GET_ARTICLE_DELETED_DETAILS_FAILED,
    GET_ARTICLE_DELETED_DETAILS_SUCCESS,
    RECOVER_DELETED_ARTICLE_START,
    RECOVER_DELETED_ARTICLE_SUCCESS,
    RECOVER_DELETED_ARTICLE_FAILED,
    ERASE_DELETED_ARTICLE_START,
    ERASE_DELETED_ARTICLE_SUCCESS,
    ERASE_DELETED_ARTICLE_FAILED,
    GET_ARTICLES_LIST_START,
    GET_ARTICLES_LIST_SUCCESS,
    GET_ARTICLES_LIST_FAILED,
    SET_EMPTY_ARTICLES_LIST,
} from '../actions/actionTypes';
import { transformDateForTree } from '../helpers/m';

const emptySearchedList = {
    count: 0,
    page_count: 1,
    results: [],
};

const clearedState = {
    articleDetails: {},
    articleDeletedDetails: {},
    fetching: false,
    errorMessage: false,
    searchedList: emptySearchedList,
};

const initialState = {
    articleDetails: {},
    articleDeletedDetails: {},
    articlesDates: [],
    fetching: false,
    errorMessage: false,
    searchedList: emptySearchedList,
};

const searchItemLoop = (arr, key, data) => {
    let newArr = JSON.parse(JSON.stringify(arr || []));
    newArr.forEach((item) => {
        if (Number(item.id) === Number(key)) {
            item.children = data;
        }
        if (item.children && item.children[0]) {
            item.children = searchItemLoop(item.children, key, data);
        }
    });
    return newArr;
};

const removeChildById = (arr, id) => {
    let newArr = JSON.parse(JSON.stringify(arr || []));
    newArr.forEach((item) => {
        if (item.children && item.children[0]) {
            item.children = item.children.filter((el) => el.id !== id);
            item.children = removeChildById(item.children, id);
        }
    });
    return newArr;
};

const updateArticlesTitles = (arr) => {
    return arr.map((el) => {
        el.title = el.about;
        el.isArticle = true;
        return el;
    });
};

const articlesReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_ARTICLES_DATES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ARTICLES_DATES_SUCCESS:
            const data = transformDateForTree(payload?.results || []);
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articlesDates: data,
            };
        case GET_ARTICLES_DATES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения списка дат',
            };
        case GET_ARTICLE_DETAILS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ARTICLE_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: payload || {},
            };
        case GET_ARTICLE_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения статьи',
            };
        case GET_ARTICLE_DELETED_DETAILS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ARTICLE_DELETED_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDeletedDetails: payload || {},
            };
        case GET_ARTICLE_DELETED_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения статьи',
            };
        case GET_ARTICLES_LIST_BY_DATE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ARTICLES_LIST_BY_DATE_SUCCESS:
            const res = updateArticlesTitles(payload.data?.results || []);
            let arr = searchItemLoop(state.articlesDates, payload.id, res);
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articlesDates: arr || [],
            };
        case GET_ARTICLES_LIST_BY_DATE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения списка писем',
            };
        case SET_ARTICLE_FAVOURITE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_ARTICLE_FAVOURITE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: {
                    ...state.articleDetails,
                    my_favourite: true,
                },
            };
        case SET_ARTICLE_FAVOURITE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case REMOVE_ARTICLE_FAVOURITE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case REMOVE_ARTICLE_FAVOURITE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: {
                    ...state.articleDetails,
                    my_favourite: false,
                },
            };
        case REMOVE_ARTICLE_FAVOURITE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case SET_ARTICLE_LIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_ARTICLE_LIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: {
                    ...state.articleDetails,
                    my_like: true,
                    my_dislike: false,
                    dislikes_count: state.articleDetails.my_dislike
                        ? state.articleDetails.dislikes_count - 1
                        : state.articleDetails.dislikes_count,
                    likes_count: (state.articleDetails.likes_count | 0) + 1,
                },
            };
        case SET_ARTICLE_LIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case REMOVE_ARTICLE_LIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case REMOVE_ARTICLE_LIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: {
                    ...state.articleDetails,
                    my_like: false,
                    likes_count: (state.articleDetails.likes_count | 1) - 1,
                },
            };
        case REMOVE_ARTICLE_LIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };

        case SET_ARTICLE_DISLIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_ARTICLE_DISLIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: {
                    ...state.articleDetails,
                    my_dislike: true,
                    my_like: false,
                    likes_count: state.articleDetails.my_like
                        ? state.articleDetails.likes_count - 1
                        : state.articleDetails.likes_count,
                    dislikes_count:
                        (state.articleDetails.dislikes_count | 0) + 1,
                },
            };
        case SET_ARTICLE_DISLIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case REMOVE_ARTICLE_DISLIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case REMOVE_ARTICLE_DISLIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: {
                    ...state.articleDetails,
                    my_dislike: false,
                    dislikes_count:
                        (state.articleDetails.dislikes_count | 1) - 1,
                },
            };
        case REMOVE_ARTICLE_DISLIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case MASS_REMOVE_ARTICLES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case MASS_REMOVE_ARTICLES_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case MASS_REMOVE_ARTICLES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка при удалении писем',
            };
        case REMOVE_ITEM_FROM_ARTICLE_DATA_BY_ID:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articlesDates: removeChildById(state.articlesDates, payload),
            };
        case CHANGE_ARTICLES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CHANGE_ARTICLES_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: {
                    ...state.articleDetails,
                    status: payload.status || 'agreed',
                },
            };
        case CHANGE_ARTICLES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка смены статуса',
            };
        case RECOVER_DELETED_ARTICLE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case RECOVER_DELETED_ARTICLE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case RECOVER_DELETED_ARTICLE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка восстановления статьи',
            };
        case ERASE_DELETED_ARTICLE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case ERASE_DELETED_ARTICLE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case ERASE_DELETED_ARTICLE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления статьи',
            };
        case UPDATE_ARTICLE_STATUS:
            return {
                ...state,
                articleDetails: {
                    ...state.articleDetails,
                    status: payload.status,
                },
            };
        case CLEAR_ARTICLE_DETAILS:
            return {
                ...state,
                ...clearedState,
            };
        case GET_ARTICLES_LIST_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ARTICLES_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                searchedList: {
                    ...payload,
                    results: [
                        ...state.searchedList.results,
                        ...payload.results,
                    ],
                },
            };
        case GET_ARTICLES_LIST_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка статей',
            };
        case SET_EMPTY_ARTICLES_LIST:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                searchedList: emptySearchedList,
            };
        default:
            return state;
    }
};

export default articlesReducer;
