import {
    UPDATE_AGREEMENT_PAGE,
    GET_APPROVAL_DETAILS_START,
    GET_APPROVAL_DETAILS_SUCCESS,
    GET_APPROVAL_DETAILS_FAILED,
    GET_APPROVALS_COUNT_START,
    GET_APPROVALS_COUNT_SUCCESS,
    GET_APPROVALS_COUNT_FAILED,
} from '../actions/actionTypes';

const initialState = {
    count: {},
    currentPage: 1,
};

const agreementReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_AGREEMENT_PAGE:
            return {
                ...state,
                currentPage: payload || {},
            };
        case GET_APPROVAL_DETAILS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_APPROVAL_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                onapproval: payload.results.length ? payload.results[0] : null,
            };
        case GET_APPROVAL_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message ||
                    'Ошибка получения сущности для согласования',
            };
        case GET_APPROVALS_COUNT_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_APPROVALS_COUNT_SUCCESS:
            return {
                ...state,
                count: payload,
                fetching: false,
                errorMessage: false,
            };
        case GET_APPROVALS_COUNT_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message ||
                    'Ошибка получения количества согласований',
            };
        default:
            return state;
    }
};

export default agreementReducer;
