import { getFromStorage, removeFromStorage, setToStorage } from './storage';
import { checkIfTest } from './m';
import { ALL_TABLES_SAVE_KEY } from './constants';

export const User = {
    login(user) {
        setToStorage('user', user);
    },

    logout() {
        User.saveTriedUrl();

        removeFromStorage('user');
        removeFromStorage('userName');
        removeFromStorage('rules');
        removeFromStorage(ALL_TABLES_SAVE_KEY);

        removeFromStorage('ROCP_token');
        removeFromStorage('ROCP_tokenExpire');
        removeFromStorage('ROCP_refreshTokenExpire');
        removeFromStorage('ROCP_refreshToken');
        removeFromStorage('ROCP_idToken');
        removeFromStorage('ROCP_loginInProgress');
        removeFromStorage('ROCP_refreshInProgress');
        removeFromStorage('ROCP_tokenIsRefreshing');
        removeFromStorage('PKCE_code_verifier');
        removeFromStorage('failedQueue');
    },

    get() {
        return getFromStorage('user');
    },

    isLoggedIn() {
        const user = getFromStorage('user');
        return !!(user && user.token);
    },

    getUserName() {
        return getFromStorage('userName');
    },

    getToken() {
        const user = getFromStorage('user');
        return user && user.token;
    },

    getIdToken() {
        const user = getFromStorage('user');
        return user && user.idToken;
    },

    saveTriedUrl() {
        const EXCLUDED_PATHS = ['/login', '/main', '/auth'];
        const path = window.location.pathname;
        const triedUrl = getFromStorage('triedUrl');
        const hasExcluded = EXCLUDED_PATHS.some((el) => path.includes(el));

        if (!triedUrl && path !== '/' && !hasExcluded) {
            setToStorage('triedUrl', window.location.href);
        }
    },

    setToken(token) {
        const user = getFromStorage('user');
        user.token = token;
        setToStorage('user', user);
    },

    getRefreshToken() {
        const user = getFromStorage('user');
        return user && user.refreshToken;
    },

    getHeader() {
        let type = 'Token';
        if (!checkIfTest()) {
            type = 'Bearer';
        }
        return `${type} ${this.getToken()}`;
    },

    getQueryHeader() {
        return encodeURI(this.getHeader());
    },

    getRefreshHeader() {
        let type = 'Token';
        if (!checkIfTest()) {
            type = 'Bearer';
        }
        return `${type} ${this.getRefreshToken()}`;
    },

    getProdRefreshToken() {
        return getFromStorage('ROCP_refreshToken');
    },
};

export default User;
