import {
    GET_PROFILE_INFO_START,
    GET_PROFILE_INFO_SUCCESS,
    GET_PROFILE_INFO_FAILED,
    LOCAL_UPDATE_PROFILE_INFO,
    UPDATE_PROFILE_SUBSCRIPTIONS_SUCCESS,
    UPDATE_PROFILE_SUBSCRIPTIONS_FAILED,
    UPDATE_PROFILE_SUBSCRIPTIONS_START,
    UPDATE_PROFILE_INFO_START,
    UPDATE_PROFILE_INFO_SUCCESS,
    UPDATE_PROFILE_INFO_FAILED,
} from '../actions/actionTypes';

const initialState = {
    profile: {},
    fetching: false,
    errorMessage: false,
};

const profileReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_PROFILE_INFO_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                profile: payload || {},
            };
        case GET_PROFILE_INFO_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения профиля',
            };
        case UPDATE_PROFILE_SUBSCRIPTIONS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_PROFILE_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                profile: { ...state.profile, ...payload },
            };
        case UPDATE_PROFILE_SUBSCRIPTIONS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка обновления подписок профиля',
            };
        case LOCAL_UPDATE_PROFILE_INFO:
            return {
                ...state,
                profile: payload || {},
            };
        case UPDATE_PROFILE_INFO_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                profile: { ...state.profile, ...payload },
            };
        case UPDATE_PROFILE_INFO_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка обновления профиля',
            };
        default:
            return state;
    }
};

export default profileReducer;
