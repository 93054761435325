import React, { useEffect, useContext, useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import { AuthContext, AuthProvider } from 'react-oauth2-code-pkce';
import store from './store';
import { ROUTES, LOGIN_ROUTE } from './helpers/routes';
import { setToStorage } from './helpers/storage';
import User from './helpers/user';
import { checkIfTest } from './helpers/m';
import NotificationProvider from './containers/NotificationProvider';
import { checkForFailed } from './helpers/api';
import Loading from './components/CommonParts/Loading';
import { AppContext } from './helpers/context';
import './styles/App.css';
import './styles/tinymce.css';

const authConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authorizationEndpoint: `${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth`,
    logoutEndpoint: `${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`,
    tokenEndpoint: `${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    redirectUri: process.env.REACT_APP_KEYCLOAK_CALLBACK,
    scope: 'openid',
    decodeToken: true,
    autoLogin: true,
};

function PrivateRoute({ ...rest }) {
    if (checkIfTest() && !User.isLoggedIn()) {
        User.saveTriedUrl();
        return <Redirect to={{ pathname: LOGIN_ROUTE }} />;
    }

    return <Route {...rest} />;
}

const KeycloakWrapper = ({ children }) => {
    const { token, error, loginInProgress, logOut } = useContext(AuthContext);
    const loggedIn = User.isLoggedIn();
    setToStorage('ROCP_refreshInProgress', true);

    useLayoutEffect(() => {
        if (!loggedIn) {
            User.saveTriedUrl();
        }
    }, []);

    useEffect(async () => {
        if (token) {
            setToStorage('user', { token });
            await checkForFailed();
        }
    }, [token]);

    useEffect(async () => {
        if (error) {
            User.saveTriedUrl();
            await logOut();
            User.logout();
            window.location.replace(LOGIN_ROUTE);
        }
    }, [error]);

    if (loginInProgress || !token || error) {
        return <Loading />;
    }

    return loggedIn || token ? (
        children
    ) : (
        <Redirect to={{ pathname: LOGIN_ROUTE }} />
    );
};

const InnerApp = () => (
    <Provider store={store}>
        <NotificationProvider>
            <Router>
                <Switch>
                    {ROUTES.map((route) =>
                        route.private ? (
                            <PrivateRoute key={route.path} {...route} />
                        ) : (
                            <Route key={route.path} {...route} />
                        ),
                    )}
                </Switch>
            </Router>
        </NotificationProvider>
    </Provider>
);

const KeycloakApp = () => (
    <AuthProvider authConfig={authConfig}>
        <KeycloakWrapper>
            <InnerApp />
        </KeycloakWrapper>
    </AuthProvider>
);

const App = () =>
    checkIfTest() ? (
        <AppContext.Provider value={{}}>
            <InnerApp />
        </AppContext.Provider>
    ) : (
        <KeycloakApp />
    );

export default App;
