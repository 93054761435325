import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_COMMENTS_START,
    GET_COMMENTS_SUCCESS,
    GET_COMMENTS_FAILED,
    CREATE_COMMENT_START,
    CREATE_COMMENT_SUCCESS,
    CREATE_COMMENT_FAILED,
    GET_ADMIN_COMMENTS_START,
    GET_ADMIN_COMMENTS_SUCCESS,
    GET_ADMIN_COMMENTS_FAILED,
    CREATE_ADMIN_COMMENT_START,
    CREATE_ADMIN_COMMENT_SUCCESS,
    CREATE_ADMIN_COMMENT_FAILED,
    UPDATE_COMMENT_START,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_COMMENT_FAILED,
    DELETE_COMMENT_START,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILED,
    UPDATE_ADMIN_COMMENT_START,
    UPDATE_ADMIN_COMMENT_FAILED,
    UPDATE_ADMIN_COMMENT_SUCCESS,
    DELETE_ADMIN_COMMENT_START,
    DELETE_ADMIN_COMMENT_SUCCESS,
    DELETE_ADMIN_COMMENT_FAILED,
} from '../actions/actionTypes';
import { API_DOMAIN } from '../helpers/constants';
import { request } from '../helpers/api';

function* handleGetComments(action) {
    try {
        const url = `${API_DOMAIN}/${action.payload}`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_COMMENTS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_COMMENTS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_COMMENTS_FAILED, error });
    }
}

function* handleGetAdminComments(action) {
    try {
        const url = `${API_DOMAIN}/${action.payload}`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_ADMIN_COMMENTS_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_ADMIN_COMMENTS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_ADMIN_COMMENTS_FAILED, error });
    }
}

function* handleCreateComment(action) {
    try {
        const url = `${API_DOMAIN}/${action.payload.url}`;
        const data = yield call(request.post, url, action.payload.data);
        if (data) {
            return yield put({ type: CREATE_COMMENT_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_COMMENT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_COMMENT_FAILED, error });
    }
}

function* handleUpdateComment(action) {
    try {
        const { data: obj, url: lUrl } = action.payload;
        const url = `${API_DOMAIN}/${lUrl}${obj.id}/`;
        const data = yield call(request.put, url, obj);
        if (data) {
            return yield put({
                type: UPDATE_COMMENT_SUCCESS,
                payload: { ...obj, ...data },
            });
        }
        return yield put({ type: UPDATE_COMMENT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_COMMENT_FAILED, error });
    }
}

function* handleDeleteComment(action) {
    try {
        const { data: obj, url: lUrl } = action.payload;
        const url = `${API_DOMAIN}/${lUrl}${obj.id}/`;
        const data = yield call(request.delete, url, obj);
        if (data) {
            return yield put({
                type: DELETE_COMMENT_SUCCESS,
                payload: obj,
            });
        }
        return yield put({ type: DELETE_COMMENT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: DELETE_COMMENT_FAILED, error });
    }
}

function* handleCreateAdminComment(action) {
    try {
        const url = `${API_DOMAIN}/${action.payload.url}`;
        const data = yield call(request.post, url, action.payload.data);
        if (data) {
            return yield put({
                type: CREATE_ADMIN_COMMENT_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: CREATE_ADMIN_COMMENT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_ADMIN_COMMENT_FAILED, error });
    }
}

function* handleUpdateAdminComment(action) {
    try {
        const { data: obj, url: lUrl } = action.payload;
        const url = `${API_DOMAIN}/${lUrl}${obj.id}/`;
        const data = yield call(request.put, url, obj);
        if (data) {
            return yield put({
                type: UPDATE_ADMIN_COMMENT_SUCCESS,
                payload: { ...obj, ...data },
            });
        }
        return yield put({ type: UPDATE_ADMIN_COMMENT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_ADMIN_COMMENT_FAILED, error });
    }
}

function* handleDeleteAdminComment(action) {
    try {
        const { data: obj, url: lUrl } = action.payload;
        const url = `${API_DOMAIN}/${lUrl}${obj.id}/`;
        const data = yield call(request.delete, url, obj);
        if (data) {
            return yield put({
                type: DELETE_ADMIN_COMMENT_SUCCESS,
                payload: obj,
            });
        }
        return yield put({ type: DELETE_ADMIN_COMMENT_FAILED, error: data });
    } catch (error) {
        return yield put({ type: DELETE_ADMIN_COMMENT_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_COMMENTS_START, handleGetComments);
    yield takeLatest(GET_ADMIN_COMMENTS_START, handleGetAdminComments);
    yield takeLatest(CREATE_COMMENT_START, handleCreateComment);
    yield takeLatest(UPDATE_COMMENT_START, handleUpdateComment);
    yield takeLatest(DELETE_COMMENT_START, handleDeleteComment);
    yield takeLatest(CREATE_ADMIN_COMMENT_START, handleCreateAdminComment);
    yield takeLatest(UPDATE_ADMIN_COMMENT_START, handleUpdateAdminComment);
    yield takeLatest(DELETE_ADMIN_COMMENT_START, handleDeleteAdminComment);
}
