import {
    GET_ARTICLES_DETAILS_START,
    GET_ARTICLES_DETAILS_SUCCESS,
    GET_ARTICLES_DETAILS_FAILED,
    CREATE_ARTICLE_START,
    CREATE_ARTICLE_SUCCESS,
    CREATE_ARTICLE_FAILED,
    UPDATE_ARTICLE_START,
    UPDATE_ARTICLE_SUCCESS,
    UPDATE_ARTICLE_FAILED,
    DELETE_ARTICLE_START,
    DELETE_ARTICLE_SUCCESS,
    DELETE_ARTICLE_FAILED,
    CLEAR_ARTICLE_DETAILS,
    UPDATE_ARTICLE_DETAILS_SUCCESS,
} from '../actions/actionTypes';
import { sortAttachments } from '../helpers/m';

const initialState = {
    articleDetails: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
    stashedArticle: {},
};

const articlesEditReducer = (
    state = initialState,
    { type, payload, error },
) => {
    switch (type) {
        case GET_ARTICLES_DETAILS_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_ARTICLES_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                articleDetails: sortAttachments(payload || {}),
            };
        case GET_ARTICLES_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения инфо. письма',
            };
        case CREATE_ARTICLE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_ARTICLE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
                articleDetails: payload,
            };
        case CREATE_ARTICLE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания инфо. письма',
            };
        case UPDATE_ARTICLE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_ARTICLE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_ARTICLE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка сохранения инфо. письма',
            };
        case DELETE_ARTICLE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case DELETE_ARTICLE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case DELETE_ARTICLE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления категории',
            };
        case UPDATE_ARTICLE_DETAILS_SUCCESS:
            return {
                ...state,
                errorMessage: false,
                stashedArticle: payload,
            };
        case CLEAR_ARTICLE_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default articlesEditReducer;
