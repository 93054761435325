import {
    GET_QUESTIONS_START,
    GET_QUESTIONS_SUCCESS,
    GET_QUESTIONS_FAILED,
} from '../actions/actionTypes';

const initialState = {
    data: [],
    fetching: false,
    errorMessage: false,
};

const questionsReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_QUESTIONS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload.results,
            };
        case GET_QUESTIONS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка вопросов',
            };
        default:
            return state;
    }
};

export default questionsReducer;
