import React, { lazy, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
// import { AppContext } from '../../../helpers/context';
import { toggleNavBar } from '../../../actions/mainAction';
import { DEFAULT_ROUTE } from '../../../helpers/routes';
import { addScript, checkIfTest, removeScript } from '../../../helpers/m';
import User from '../../../helpers/user';
import NotificationIcons from '../NotificationIcons';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { ReactComponent as LogoImg } from '../../../assets/logoWithName.svg';
import HeaderSearchComponent from '../HeaderSearch';
import styles from './header.module.scss';

const MoreMenu = lazy(() => import('../MoreMenu'));

const SCRIPT_ID = 'mainScriptID';
const SCRIPT_URL_ID = 'qnxgTCBh:e07dddc67517f28e01dc187924ab51edccfc4e1c';

const Header = ({ simple }) => {
    // const { keycloak } = useContext(AppContext);
    const { token, logOut } = useContext(AuthContext);
    const isTest = checkIfTest();
    const isLoggedIn = isTest ? User.isLoggedIn() : token;
    const profile = useSelector((state) => state.profileReducer.profile);
    const name = profile?.username || '';
    const photo = profile?.photo || '';
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const toggleMenu = () => dispatch(toggleNavBar());

    useEffect(() => {
        if (simple) {
            removeScript(SCRIPT_ID);
        } else if (!document.getElementById(SCRIPT_ID)) {
            addScript(SCRIPT_ID, SCRIPT_URL_ID);
        }
    }, [simple, location?.pathname]);

    const onClickLogout = () => {
        User.logout();
        if (!isTest) {
            return logOut().finally(() => window.location.replace('/login'));
        }
        return window.location.replace('/login');
    };

    const onClickProfile = () => {
        history.push('/profile');
    };

    const MENU_ITEMS = [
        {
            name: 'Профиль',
            onClick: onClickProfile,
        },
        {
            name: 'Выйти',
            onClick: onClickLogout,
        },
    ];

    const profileIcon = photo ? (
        <img src={photo} className={styles.photo} alt="profile" />
    ) : (
        <div className={styles.photo} />
    );

    return (
        <div className={styles.appHeader}>
            <div className={styles.appHeaderInner}>
                {simple ? (
                    <Link
                        to={DEFAULT_ROUTE}
                        className={styles.headerSimpleLogo}
                    >
                        <LogoImg className={styles.headerLogo} />
                    </Link>
                ) : (
                    <div className={styles.headerFirstBlock}>
                        <MenuIcon
                            className={styles.headerMenuIcon}
                            onClick={toggleMenu}
                        />
                        <Link
                            to={DEFAULT_ROUTE}
                            className={styles.headerLogoBlock}
                        >
                            <LogoImg className={styles.headerLogo} />
                        </Link>
                    </div>
                )}
                {!simple && isLoggedIn && (
                    <>
                        <HeaderSearchComponent />
                        <div className={styles.headerSecondBlock}>
                            <div className={styles.notifyIconsBlock}>
                                <NotificationIcons />
                            </div>
                            <div className={styles.profileBlock}>
                                {name && (
                                    <span className={styles.name}>{name}</span>
                                )}
                                <MoreMenu
                                    items={MENU_ITEMS}
                                    customIcon={profileIcon}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

Header.propTypes = {
    simple: PropTypes.bool,
};

Header.defaultProps = {
    simple: false,
};

export default Header;
