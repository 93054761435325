import {
    UPDATE_MEDIA_LIST,
    GET_MEDIA_START,
    GET_MEDIA_SUCCESS,
    GET_MEDIA_FAILED,
    GET_MEDIA_LIST_START,
    GET_MEDIA_LIST_SUCCESS,
    GET_MEDIA_LIST_FAILED,
    SET_EMPTY_MEDIA_LIST,
} from '../actions/actionTypes';

const emptySearchedList = {
    count: 0,
    page_count: 1,
    results: [],
};

const initialState = {
    currentPage: 1,
    data: [],
    searchedList: emptySearchedList,
};

const mediaReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case UPDATE_MEDIA_LIST:
            return {
                ...state,
                currentPage: payload || {},
            };
        case GET_MEDIA_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_MEDIA_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload.results,
            };
        case GET_MEDIA_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка медиа файлов',
            };
        case GET_MEDIA_LIST_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_MEDIA_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                searchedList: {
                    ...payload,
                    results: [
                        ...state.searchedList.results,
                        ...payload.results,
                    ],
                },
            };
        case GET_MEDIA_LIST_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка вложениий',
            };
        case SET_EMPTY_MEDIA_LIST:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                searchedList: emptySearchedList,
            };
        default:
            return state;
    }
};

export default mediaReducer;
