import {
    CREATE_CATEGORY_START,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILED,
    GET_CATEGORY_DETAILS_START,
    GET_CATEGORY_DETAILS_SUCCESS,
    GET_CATEGORY_DETAILS_FAILED,
    GET_CATEGORY_EDIT_DETAILS_START,
    GET_CATEGORY_EDIT_DETAILS_SUCCESS,
    GET_CATEGORY_EDIT_DETAILS_FAILED,
    CLEAR_CATEGORY_DATA,
    CLEAR_CATEGORY_EDIT_DATA,
    UPDATE_CATEGORY_START,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILED,
    DELETE_CATEGORY_START,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILED,
} from '../actions/actionTypes';

const initialState = {
    categoryDetails: {},
    categoryEditDetails: {},
    categoryToUpdate: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const categoryEditReducer = (
    state = initialState,
    { type, payload, error },
) => {
    switch (type) {
        case CREATE_CATEGORY_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case CREATE_CATEGORY_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания новости',
            };
        case GET_CATEGORY_DETAILS_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_CATEGORY_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                categoryDetails: payload || {},
            };
        case GET_CATEGORY_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения категории',
            };
        case GET_CATEGORY_EDIT_DETAILS_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_CATEGORY_EDIT_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                categoryEditDetails: payload || {},
            };
        case GET_CATEGORY_EDIT_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения категории',
            };
        case UPDATE_CATEGORY_START:
            let newObj = {};
            if (payload.item && payload?.id === state.categoryDetails?.id) {
                newObj = {
                    ...state.categoryDetails,
                    ...payload.item,
                };
            }
            return {
                ...state,
                categoryToUpdate: newObj,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryDetails: state.categoryToUpdate?.id
                    ? state.categoryToUpdate
                    : state.categoryDetails,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_CATEGORY_FAILED:
            return {
                ...state,
                categoryToUpdate: {},
                fetching: false,
                errorMessage: error?.message || 'Ошибка сохранения категории',
            };
        case DELETE_CATEGORY_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case DELETE_CATEGORY_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка удаления категории',
            };
        case CLEAR_CATEGORY_DATA:
            return initialState;
        case CLEAR_CATEGORY_EDIT_DATA:
            return {
                ...state,
                categoryEditDetails: {},
                categoryToUpdate: {},
                fetching: false,
                errorMessage: false,
                editDone: false,
            };
        default:
            return state;
    }
};

export default categoryEditReducer;
