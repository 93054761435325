import { call, put, takeEvery } from 'redux-saga/effects';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';
import notify from '../helpers/notify';
import {
    GET_NEWS_DETAILS_START,
    GET_NEWS_DETAILS_SUCCESS,
    GET_NEWS_DETAILS_FAILED,
    CREATE_NEWS_START,
    CREATE_NEWS_FAILED,
    CREATE_NEWS_SUCCESS,
    UPDATE_NEWS_START,
    UPDATE_NEWS_SUCCESS,
    UPDATE_NEWS_FAILED,
    DELETE_NEWS_START,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILED,
} from '../actions/actionTypes';

function* handleGetNewsEdit(action) {
    try {
        const url = `${API_DOMAIN}/articles/news/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({ type: GET_NEWS_DETAILS_SUCCESS, payload: data });
        }
        return yield put({ type: GET_NEWS_DETAILS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_NEWS_DETAILS_FAILED, error });
    }
}

function* handleCreateNews(action) {
    try {
        const url = `${API_DOMAIN}/articles/news/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({ type: CREATE_NEWS_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_NEWS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_NEWS_FAILED, error });
    }
}

function* handleUpdateNews(action) {
    try {
        const url = `${API_DOMAIN}/articles/news/${action.payload.id}/`;
        const data = yield call(request.put, url, action.payload);
        if (data) {
            return yield put({ type: UPDATE_NEWS_SUCCESS, payload: data });
        }
        return yield put({ type: UPDATE_NEWS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_NEWS_FAILED, error });
    }
}

function* handleDeleteNews(action) {
    try {
        const url = `${API_DOMAIN}/articles/news/${action.payload.id}/`;
        const data = yield call(request.delete, url);
        if (data) {
            notify.success('Новость удалена');
            return yield put({ type: DELETE_NEWS_SUCCESS, payload: data });
        }
        return yield put({ type: DELETE_NEWS_FAILED, error: data });
    } catch (error) {
        return yield put({ type: DELETE_NEWS_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_NEWS_DETAILS_START, handleGetNewsEdit);
    yield takeEvery(CREATE_NEWS_START, handleCreateNews);
    yield takeEvery(UPDATE_NEWS_START, handleUpdateNews);
    yield takeEvery(DELETE_NEWS_START, handleDeleteNews);
}
