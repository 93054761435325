import {
    GET_LETTERS_DETAILS_START,
    GET_LETTERS_DETAILS_SUCCESS,
    GET_LETTERS_DETAILS_FAILED,
    CREATE_LETTERS_START,
    CREATE_LETTERS_SUCCESS,
    CREATE_LETTERS_FAILED,
    UPDATE_LETTERS_START,
    UPDATE_LETTERS_SUCCESS,
    UPDATE_LETTERS_FAILED,
    UPDATE_LETTERS_POSM_DATES_START,
    UPDATE_LETTERS_POSM_DATES_SUCCESS,
    UPDATE_LETTERS_POSM_DATES_FAILED,
    CLEAR_LETTERS_DETAILS,
} from '../actions/actionTypes';
import { sortAttachments } from '../helpers/m';

const initialState = {
    letterDetails: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const lettersEditReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_LETTERS_DETAILS_START:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case GET_LETTERS_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: sortAttachments(payload || {}),
            };
        case GET_LETTERS_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения инфо. письма',
            };
        case CREATE_LETTERS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_LETTERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: payload?.id,
            };
        case CREATE_LETTERS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания инфо. письма',
            };
        case UPDATE_LETTERS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_LETTERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: payload?.id,
            };
        case UPDATE_LETTERS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка сохранения инфо. письма',
            };
        case UPDATE_LETTERS_POSM_DATES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_LETTERS_POSM_DATES_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case UPDATE_LETTERS_POSM_DATES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка сохранения инфо. письма',
            };
        case CLEAR_LETTERS_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default lettersEditReducer;
