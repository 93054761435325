export const getFromStorage = (key) => {
    let data;
    try {
        if (!key) return;
        data = JSON.parse(localStorage.getItem(key));
    } catch (e) {
        return null;
    }
    return data;
};

export const setToStorage = (key, value) => {
    if (!key || !value) return;
    localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromStorage = (key) => {
    if (!key) return;
    localStorage.removeItem(key);
};
