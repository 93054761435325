import {
    CREATE_FUNCTION_START,
    CREATE_FUNCTION_SUCCESS,
    CREATE_FUNCTION_FAILED,
    UPDATE_FUNCTION_START,
    UPDATE_FUNCTION_SUCCESS,
    UPDATE_FUNCTION_FAILED,
    CLEAR_FUNCTION_DETAILS,
} from '../actions/actionTypes';

const initialState = {
    functionDetails: {},
    fetching: false,
    errorMessage: false,
    editDone: false,
};

const functionEditReducer = (
    state = initialState,
    { type, payload, error },
) => {
    switch (type) {
        case CREATE_FUNCTION_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CREATE_FUNCTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case CREATE_FUNCTION_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка создания функции',
            };
        case UPDATE_FUNCTION_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case UPDATE_FUNCTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                editDone: true,
            };
        case UPDATE_FUNCTION_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка сохранения функции',
            };
        case CLEAR_FUNCTION_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default functionEditReducer;
