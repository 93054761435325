import { API_DOMAIN } from '../helpers/constants';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { request } from '../helpers/api';
import {
    GET_SCHEDULE_START,
    GET_SCHEDULE_SUCCESS,
    GET_SCHEDULE_FAILED,
    CREATE_SCHEDULE_START,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILED,
    UPDATE_SCHEDULE_START,
    UPDATE_SCHEDULE_FAILED,
    UPDATE_SCHEDULE_SUCCESS,
} from '../actions/actionTypes';
import notify from '../helpers/notify';

function* handleGetSchedule(action) {
    try {
        const url = `${API_DOMAIN}/periodicals/${action.payload}/`;
        const data = yield call(request.get, url);
        if (data) {
            return yield put({
                type: GET_SCHEDULE_SUCCESS,
                payload: data,
            });
        }
        return yield put({ type: GET_SCHEDULE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: GET_SCHEDULE_FAILED, error });
    }
}

function* handleCreateSchedule(action) {
    try {
        const url = `${API_DOMAIN}/periodicals/`;
        const data = yield call(request.post, url, action.payload);
        if (data) {
            return yield put({ type: CREATE_SCHEDULE_SUCCESS, payload: data });
        }
        return yield put({ type: CREATE_SCHEDULE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: CREATE_SCHEDULE_FAILED, error });
    }
}

function* handleUpdateSchedule(action) {
    try {
        const obj = action.payload;
        const url = `${API_DOMAIN}/periodicals/${obj.id}/`;
        delete obj.id;
        const data = yield call(request.put, url, obj);
        if (data) {
            notify.success('Расписание успешно изменено');
            return yield put({ type: UPDATE_SCHEDULE_SUCCESS, payload: data });
        }
        return yield put({ type: UPDATE_SCHEDULE_FAILED, error: data });
    } catch (error) {
        return yield put({ type: UPDATE_SCHEDULE_FAILED, error });
    }
}

export default function* rootSaga() {
    yield takeEvery(GET_SCHEDULE_START, handleGetSchedule);
    yield takeEvery(CREATE_SCHEDULE_START, handleCreateSchedule);
    yield takeEvery(UPDATE_SCHEDULE_START, handleUpdateSchedule);
}
