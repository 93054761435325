import {
    GET_LETTER_DETAILS_START,
    GET_LETTER_DETAILS_SUCCESS,
    GET_LETTER_DETAILS_FAILED,
    SET_LETTER_FAVOURITE_START,
    SET_LETTER_FAVOURITE_SUCCESS,
    SET_LETTER_FAVOURITE_FAILED,
    REMOVE_LETTER_FAVOURITE_START,
    REMOVE_LETTER_FAVOURITE_SUCCESS,
    REMOVE_LETTER_FAVOURITE_FAILED,
    CLEAR_LETTER_DETAILS,
    SET_LETTER_LIKE_START,
    SET_LETTER_LIKE_SUCCESS,
    SET_LETTER_LIKE_FAILED,
    REMOVE_LETTER_LIKE_START,
    REMOVE_LETTER_LIKE_SUCCESS,
    REMOVE_LETTER_LIKE_FAILED,
    SET_LETTER_DISLIKE_START,
    SET_LETTER_DISLIKE_SUCCESS,
    SET_LETTER_DISLIKE_FAILED,
    REMOVE_LETTER_DISLIKE_START,
    REMOVE_LETTER_DISLIKE_SUCCESS,
    REMOVE_LETTER_DISLIKE_FAILED,
    REMOVE_ITEM_FROM_LETTER_DATA_BY_ID,
    GET_LETTERS_DATES_START,
    GET_LETTERS_DATES_SUCCESS,
    GET_LETTERS_DATES_FAILED,
    GET_LETTERS_UNPUBLISHED_START,
    GET_LETTERS_UNPUBLISHED_FAILED,
    GET_LETTERS_UNPUBLISHED_SUCCESS,
    GET_LETTERS_LIST_BY_DATE_START,
    GET_LETTERS_LIST_BY_DATE_SUCCESS,
    GET_LETTERS_LIST_BY_DATE_FAILED,
    COPY_ONE_LETTER_START,
    COPY_ONE_LETTER_SUCCESS,
    COPY_ONE_LETTER_FAILED,
    ADD_TO_CHAIN_LETTER_START,
    ADD_TO_CHAIN_LETTER_SUCCESS,
    ADD_TO_CHAIN_LETTER_FAILED,
    MASS_REMOVE_LETTERS_START,
    MASS_REMOVE_LETTERS_SUCCESS,
    MASS_REMOVE_LETTERS_FAILED,
    GET_LETTERS_CHAIN_LIST_START,
    GET_LETTERS_CHAIN_LIST_SUCCESS,
    GET_LETTERS_CHAIN_LIST_FAILED,
    CHANGE_LETTER_START,
    CHANGE_LETTER_SUCCESS,
    CHANGE_LETTER_FAILED,
    UPDATE_LETTER_STATUS,
    GET_LETTERS_LIST_START,
    GET_LETTERS_LIST_SUCCESS,
    GET_LETTERS_LIST_FAILED,
    SET_EMPTY_LETTERS_LIST,
} from '../actions/actionTypes';
import { transformDateForTree, sortArrayByKey } from '../helpers/m';

const emptySearchedList = {
    count: 0,
    page_count: 1,
    results: [],
};

const clearedState = {
    letterDetails: {},
    letterChain: [],
    fetching: false,
    errorMessage: false,
    searchedList: emptySearchedList,
};

const initialState = {
    unpublishedCount: 0,
    letterDetails: {},
    lettersDates: [],
    letterChain: [],
    fetching: false,
    errorMessage: false,
    searchedList: emptySearchedList,
};

const searchItemLoop = (arr, key, data) => {
    let newArr = JSON.parse(JSON.stringify(arr || []));
    newArr.forEach((item) => {
        if (Number(item.id) === Number(key)) {
            item.children = data;
        }
        if (item.children && item.children[0]) {
            item.children = searchItemLoop(item.children, key, data);
        }
    });
    return newArr;
};

const removeChildById = (arr, id) => {
    let newArr = JSON.parse(JSON.stringify(arr || []));
    newArr.forEach((item) => {
        if (item.children && item.children[0]) {
            item.children = item.children.filter((el) => el.id !== id);
            item.children = removeChildById(item.children, id);
        }
    });
    return newArr;
};

const updateLettersTitles = (arr) => {
    return arr.map((el) => {
        el.title = el.about;
        el.isArticle = true;
        return el;
    });
};

const lettersReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_LETTERS_DATES_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_LETTERS_DATES_SUCCESS:
            let data = transformDateForTree(payload?.results || []);
            data = sortArrayByKey(data, 'id', false);
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                lettersDates: data,
            };
        case GET_LETTERS_DATES_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения списка дат',
            };
        case GET_LETTERS_UNPUBLISHED_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_LETTERS_UNPUBLISHED_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                unpublishedCount: payload || 0,
            };
        case GET_LETTERS_UNPUBLISHED_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message ||
                    'Ошибка получения количества неопубликованных',
            };
        case GET_LETTER_DETAILS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_LETTER_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: payload || {},
            };
        case GET_LETTER_DETAILS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения письма',
            };
        case GET_LETTERS_LIST_BY_DATE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_LETTERS_LIST_BY_DATE_SUCCESS:
            const res = updateLettersTitles(payload.data?.results || []);
            let arr = searchItemLoop(state.lettersDates, payload.id, res);
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                lettersDates: arr || [],
            };
        case GET_LETTERS_LIST_BY_DATE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения списка писем',
            };
        case SET_LETTER_FAVOURITE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_LETTER_FAVOURITE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: {
                    ...state.letterDetails,
                    my_favourite: true,
                },
            };
        case SET_LETTER_FAVOURITE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case REMOVE_LETTER_FAVOURITE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case REMOVE_LETTER_FAVOURITE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: {
                    ...state.letterDetails,
                    my_favourite: false,
                },
            };
        case REMOVE_LETTER_FAVOURITE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case SET_LETTER_LIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_LETTER_LIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: {
                    ...state.letterDetails,
                    my_like: true,
                    my_dislike: false,
                    dislikes_count: state.letterDetails.my_dislike
                        ? state.letterDetails.dislikes_count - 1
                        : state.letterDetails.dislikes_count,
                    likes_count: state.letterDetails.likes_count + 1,
                },
            };
        case SET_LETTER_LIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case REMOVE_LETTER_LIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case REMOVE_LETTER_LIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: {
                    ...state.letterDetails,
                    my_like: false,
                    likes_count: state.letterDetails.likes_count - 1,
                },
            };
        case REMOVE_LETTER_LIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };

        case SET_LETTER_DISLIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_LETTER_DISLIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: {
                    ...state.letterDetails,
                    my_dislike: true,
                    my_like: false,
                    likes_count: state.letterDetails.my_like
                        ? state.letterDetails.likes_count - 1
                        : state.letterDetails.likes_count,
                    dislikes_count: state.letterDetails.dislikes_count + 1,
                },
            };
        case SET_LETTER_DISLIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case REMOVE_LETTER_DISLIKE_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case REMOVE_LETTER_DISLIKE_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: {
                    ...state.letterDetails,
                    my_dislike: false,
                    dislikes_count: state.letterDetails.dislikes_count - 1,
                },
            };
        case REMOVE_LETTER_DISLIKE_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка',
            };
        case COPY_ONE_LETTER_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case COPY_ONE_LETTER_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case COPY_ONE_LETTER_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка при копировании письма',
            };
        case ADD_TO_CHAIN_LETTER_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case ADD_TO_CHAIN_LETTER_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case ADD_TO_CHAIN_LETTER_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка при добавлении письма',
            };
        case MASS_REMOVE_LETTERS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case MASS_REMOVE_LETTERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case MASS_REMOVE_LETTERS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка при удалении писем',
            };
        case GET_LETTERS_CHAIN_LIST_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_LETTERS_CHAIN_LIST_SUCCESS:
            return {
                ...state,
                letterChain: payload?.results || [],
                fetching: false,
                errorMessage: false,
            };
        case GET_LETTERS_CHAIN_LIST_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения связанных писем',
            };
        case REMOVE_ITEM_FROM_LETTER_DATA_BY_ID:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                lettersDates: removeChildById(state.lettersDates, payload),
            };
        case CHANGE_LETTER_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case CHANGE_LETTER_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                letterDetails: {
                    ...state.letterDetails,
                    status: payload.status || 'agreed',
                },
            };
        case CHANGE_LETTER_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка смены статуса',
            };
        case UPDATE_LETTER_STATUS:
            return {
                ...state,
                letterDetails: {
                    ...state.letterDetails,
                    status: payload.status,
                },
            };
        case CLEAR_LETTER_DETAILS:
            return {
                ...state,
                ...clearedState,
            };
        case GET_LETTERS_LIST_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_LETTERS_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                searchedList: {
                    ...payload,
                    results: [
                        ...state.searchedList.results,
                        ...payload.results,
                    ],
                },
            };
        case GET_LETTERS_LIST_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage:
                    error?.message || 'Ошибка получения списка информ. писем',
            };
        case SET_EMPTY_LETTERS_LIST:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                searchedList: emptySearchedList,
            };
        default:
            return state;
    }
};

export default lettersReducer;
