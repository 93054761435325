import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { DEFAULT_ROUTE, LOGIN_ROUTE } from '../../../helpers/routes';
import Loading from '../Loading';

export default function AuthCallbackComponent() {
    const { token } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (token) {
            return history.push(DEFAULT_ROUTE);
        } else {
            return history.push(LOGIN_ROUTE);
        }
    }, [token]);

    return <Loading />;
}
