import {
    GET_USER_NOTIFICATIONS_START,
    GET_USER_NOTIFICATIONS_SUCCESS,
    GET_USER_NOTIFICATIONS_FAILED,
    SET_USER_NOTIFICATIONS_READ_START,
    SET_USER_NOTIFICATIONS_READ_SUCCESS,
    SET_USER_NOTIFICATIONS_READ_FAILED,
    SET_USER_NOTIFICATIONS_READ_ALL_SUCCESS,
    SET_USER_NOTIFICATIONS_READ_ALL_START,
    SET_USER_NOTIFICATIONS_READ_ALL_FAILED,
    CLEAR_USER_NOTIFICATIONS,
} from '../actions/actionTypes';

const initialState = {
    data: [],
    fetching: false,
    errorMessage: false,
};

const notificationReducer = (
    state = initialState,
    { type, payload, error },
) => {
    switch (type) {
        case GET_USER_NOTIFICATIONS_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_USER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                data: payload || [],
                fetching: false,
                errorMessage: false,
            };
        case GET_USER_NOTIFICATIONS_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения уведомлений',
            };
        case SET_USER_NOTIFICATIONS_READ_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_USER_NOTIFICATIONS_READ_SUCCESS:
            let newArr = state.data;
            newArr = newArr.filter((el) => !payload.includes(el.id));
            return {
                ...state,
                data: newArr,
                fetching: false,
                errorMessage: false,
            };
        case SET_USER_NOTIFICATIONS_READ_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка отметки уведомления',
            };
        case SET_USER_NOTIFICATIONS_READ_ALL_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case SET_USER_NOTIFICATIONS_READ_ALL_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
            };
        case SET_USER_NOTIFICATIONS_READ_ALL_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка отметки уведомлений',
            };
        case CLEAR_USER_NOTIFICATIONS:
            return initialState;
        default:
            return state;
    }
};

export default notificationReducer;
